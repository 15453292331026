import { client } from './';
import { LoginCredentials, UserSession } from '@/models';

const ENDPOINT = '/api/auth';

export function getAuth() {
  return client.get<UserSession>(ENDPOINT).then(response => response.data);
}

export function login(data: LoginCredentials) {
  return client
    .post<UserSession>(ENDPOINT, data)
    .then(response => response.data);
}

export function logout() {
  return client.delete<void>(ENDPOINT);
}
