










































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import store from '@/plugins/store';
import { CaseItem } from '@/models';
import { showFailedSnackbar } from '@/utils/snackbar';

import CaseTable from '@/components/case/CaseTable.vue';
import CreateCaseForm from '@/components/case/CreateCaseForm.vue';
import ErrorAlert from '@/components/alerts/ErrorAlert.vue';
import Heading from '@/components/Heading.vue';

export default Vue.extend({
  name: 'CaseList',
  components: {
    Heading,
    ErrorAlert,
    CaseTable,
    CreateCaseForm,
  },
  data() {
    return {
      showCreateForm: false,
      createFormVisible: false,
    };
  },
  beforeCreate: () => {
    store.dispatch('case/list/load').then(showFailedSnackbar('caseItem'));
  },
  computed: {
    ...mapState('case/list', ['entities', 'isLoading', 'loadError']),
    ...mapGetters({
      isLoadingOrSaving: 'case/list/isLoadingOrSaving',
    }),
  },
  methods: {
    retryLoad() {
      this.$store
        .dispatch('case/list/load')
        .then(showFailedSnackbar('caseItem'));
    },
    onCreate(entity: CaseItem) {
      this.$router.push({
        name: 'deceased',
        params: { caseId: entity.id!.toString() },
      });
    },
  },
  watch: {
    showCreateForm(val) {
      if (val) {
        this.createFormVisible = true;
      } else {
        setTimeout(() => (this.createFormVisible = false), 300);
      }
    },
  },
});
