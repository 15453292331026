var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{key:"login",staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',{staticStyle:{"width":"100%"}},[_c('span',[_vm._v("Dokumentgeneratorn")])])],1),_c('v-card-text',[_c('Form',{attrs:{"disable-dirty-check":""},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
        var valid = ref.valid;
        var dirty = ref.dirty;
        var submit = ref.submit;
return [_c('v-slide-y-transition',[(!!_vm.loadError)?_c('ErrorAlert',{attrs:{"error":_vm.$t(_vm.loadError),"title":_vm.$t('loading.authError'),"disabled":_vm.isLoading}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"success","loading":_vm.isLoading,"disabled":!valid || !dirty},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t('auth.login'))+" ")])],1)]}}])},[_c('transition',{attrs:{"mode":"out-in","name":"slide-y-transition"}},[_c('FormFields',{attrs:{"fields":_vm.fields,"value":_vm.data},scopedSlots:_vm._u([{key:"fields",fn:function(ref){
            var values = ref.values;
            var fieldInput = ref.fieldInput;
            var validateField = ref.validateField;
            var hasError = ref.hasError;
            var getError = ref.getError;
            var submit = ref.submit;
return [_c('v-text-field',{ref:"username",attrs:{"autofocus":"","prepend-icon":"mdi-account-outline","label":_vm.$t('form.auth.username.label'),"hint":_vm.$to('form.auth.username.hint'),"error":hasError('username'),"error-messages":getError('username')},on:{"blur":function($event){return validateField('username')},"input":function($event){return fieldInput('username')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return submit($event)}},model:{value:(values.username),callback:function ($$v) {_vm.$set(values, "username", $$v)},expression:"values.username"}}),_c('password-input-component',{attrs:{"icon":"mdi-lock-outline","label":_vm.$t('form.auth.password.label'),"hint":_vm.$to('form.auth.password.hint'),"error":hasError('password'),"error-messages":getError('password')},on:{"blur":function($event){return validateField('password')},"input":function($event){return fieldInput('password')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return submit($event)}},model:{value:(values.password),callback:function ($$v) {_vm.$set(values, "password", $$v)},expression:"values.password"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }