import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { Address, CaseItem, Person } from '@/models';
import { ADDRESS_PROPERTIES, PERSON_PROPERTIES } from '@/constants';

export function getCaseText(item: CaseItem | null): string {
  const { person } = (item || {}) as CaseItem;
  // const caseTypeText = caseType
  //   ? `${(this as any).$t(CASE_TYPE_TRANSLATIONS[caseType])}: `
  //   : '';
  if (person) {
    return `${person.firstName || ''} ${person.lastName || ''}`;
  }
  return '';
}

export function flattenPerson(data: Person): Partial<Person & Address> {
  if (data && data.personalIdNumber) {
    if (data.juridicalPerson && data.personalIdNumber.length === 10) {
      data.personalIdNumber =
        data.personalIdNumber.slice(0, 6) +
        '-' +
        data.personalIdNumber.slice(6);
    } else if (data.personalIdNumber.length === 12) {
      data.personalIdNumber =
        data.personalIdNumber.slice(0, 8) +
        '-' +
        data.personalIdNumber.slice(8);
    }
  }
  return {
    ...omit(data || {}, 'address'),
    ...(data?.address || {}),
  };
}

export function unflattenPerson(data: Person & Address): Person {
  return {
    ...pick(data, PERSON_PROPERTIES),
    address: pick(data, ADDRESS_PROPERTIES),
  };
}
