import { Module } from 'vuex';

import { createLoadingModule, LoadingState } from './loading-module';

export type ConfigState<T> = LoadingState & {
  data: T | null;
};

export function createConfigModule<T>(api: {
  load: () => Promise<T>;
}): Module<ConfigState<T>, any> {
  const loadModule = createLoadingModule();

  return {
    namespaced: true,
    state: {
      ...(loadModule.state as LoadingState),
      data: null,
    },
    mutations: {
      ...loadModule.mutations,
      SET_DATA(state: ConfigState<T>, data: T | null) {
        state.data = data;
      },
    },
    actions: {
      async load({ commit }) {
        commit('SET_LOADING', true);
        try {
          const data = await api.load();
          commit('SET_DATA', data);
          commit('SET_LOAD_ERROR', null);
          return true;
        } catch (error) {
          console.error(error);
          commit('SET_LOAD_ERROR', error);
        } finally {
          commit('SET_LOADING', false);
        }
        return false;
      },
    },
  };
}
