import axios, {AxiosError} from 'axios';

export const BASE_URL = process.env.VUE_APP_API_BASE_URL || 'https://test.docgen.bit-net.se/';
export const MainApi = 'api';

export const HEADERS = {
  "Authorization": "Authorization",
  "ContentType": "Content-Type",
  "ApplicationJson": "application/json"
};

export const ENDPOINTS = {
    ACCOUNT: "account",
    ACCOUNTS: "accounts",
    ACCOUNT_CONVERSION_RATES: "account-conversion-rates",
    ACTIVATION: "activation",
    ACTIVATION_STATUS: "activationstatus",
    ADDRESSES: "addresses",
    AUTH: "auth",
    BENEFICIARY_INSURANCES: "beneficiary-insurances",
    BEQUESTS_ACCORDING_TO_WILL: "bequests-according-to-will",
    CACHE: "cache",
    CASES: "cases",
    CONFIRM: "confirm",
    COPY: "copy",
    DISTRIBUTION_PROPERTIES: "distribution-properties",
    DOCUMENT: "document",
    DOCUMENTS: "documents",
    DOCUMENT_TEMPLATES: "document-templates",
    EMAILED: "emailed",
    EMAIL_MESSAGES: "emailmessages",
    ENTITIES: "entities",
    ENTITY: "entity",
    ESTATES: "estates",
    ESTATE_DISTRIBUTIONS: "estate-distributions",
    ESTATE_DIVISIONS: "estate-divisions",
    ESTATE_INVENTORIES: "estate-inventories",
    ESTATE_SUMMONS: "estate-summons",
    FILES: "files",
    FILE_DATA: "filedata",
    GENERIC: "generic",
    HELP: "help",
    IMAGES: "images",
    IMAGE_CATEGORIES: "image-categories",
    LATEST: "latest",
    LOGIN: "login",
    LOGS: "logs",
    MAIL: "mail",
    NAME: "name",
    NO_DIVISON_REQUESTED: "no-division-requested",
    OVERVIEW: "overview",
    OWNERSHIP: "ownership",
    OWNERSHIP_ADJUSTMENT: "ownership_adjustment",
    PARTIES: "parties",
    PARTY: "party",
    PARTY_DISTRIBUTIONS: "party-distributions",
    PARTY_RELATIONS: "partyrelations",
    PAYMENT_METHODS: "paymentmethods",
    PREVIEW: "preview",
    PRINT: "print",
    PROCEEDING: "proceeding",
    REGISTER: "register",
    ROW: "row",
    ROWS: "rows",
    SEARCH: "search",
    SESSIONS_COOKIE_USER: "sessions/cookie/user",
    STATEMENT: "statement",
    STATEMENT_ROWS: "statement-rows",
    SUMMARY: "summary",
    SYSTEM_STATUS: "system/status",
    TOKEN: "token",
    UPLOAD: "upload",
    USER_ACCOUNTS: "useraccounts",
};

export const LOGIN_PAGE_URL = BASE_URL + "/" + ENDPOINTS.AUTH + "/" + ENDPOINTS.LOGIN;

function handleError(error: AxiosError) {
  if (error.response?.status === 401) {
    location.href = LOGIN_PAGE_URL
  }

  console.log(error);

  if (error.response) {
    console.log(error.response)
    throw error.response;
  } else {
    throw error;
  }
}

/**
 * Generic get request. Normally this should be preferred to more specific requests.
 * @param endpoint
 * @param params
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export async function get(endpoint: string, params?: object) {

  return (
    await axios.get(BASE_URL + "/" + MainApi + "/" + endpoint, {
      withCredentials: true,
      params,
      headers: HEADERS
    })
      // No need to store more data then the name and the id
      .then(response => response.data)
      .catch((error) => {
        handleError(error)
      })
  )
}

/*
* Generic delete request. Normally this should be preferred to more specific requests.
* @param endpoint
* @param params
* @returns {Promise<AxiosResponse<T> | never>}
*/
export async function deleteRequest(endpoint: string, params?: object) {
  return (
    await axios
      .delete(BASE_URL + "/" + MainApi + "/" + endpoint, {
        withCredentials: true,
        params
      })
      .then(response => response.data)
      .catch((error) => {
        handleError(error)
      })
  );
}

/**
 *
 * @param endpoint
 * @param postData
 * @param params
 * @returns {Promise<any>}
 */
export async function post(endpoint: string, postData?: object, params?: object) {
  return (
    await axios
      .post(BASE_URL + "/" + MainApi + "/" + endpoint, postData, {
        withCredentials: true,
        params
      })
      .then(response => response.data)
      .catch((error) => handleError(error))
  );
}

/**
 *
 * @param endpoint
 * @param postData
 * @param params
 * @returns {Promise<any>}
 */
export async function postFile(endpoint: string, postData?: object, params?: object) {
  return (
    await axios
      .post(BASE_URL + "/" + MainApi + "/" + endpoint, postData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params
      })
      .then(response => response.data)
      .catch((error) => {
        handleError(error)
      })
  );
}

/**
 *
 * @param endpoint
 * @param params
 * @returns {Promise<any>}
 */
export async function put(endpoint: string, params: object) {
  return (
    await axios
      .put(BASE_URL + "/" + MainApi + "/" + endpoint, params, {
        withCredentials: true,
      })
      .then(response => response.data)
      .catch((error) => {
        handleError(error)
      })
  );
}

export async function patch(endpoint: string, patchData?: object | null, params?: object) {
  return (
    await axios
      .patch(BASE_URL + "/" + MainApi + "/" + endpoint, patchData, {
        withCredentials: true,
        params
      })
      .then(response => response.data)
      .catch((error) => {
        handleError(error)
      })
  );
}

