import { Module } from 'vuex';
import { StatementRows } from '@/models';
import statementRowsApi from '@/api/estate-inventory/statement-rows';
import { ESTATE_INVENTORY_API_PARAMS_GETTER } from '@/constants';

import { createSingleEntityModule, SingleEntityState } from '../utils';

export interface StatementRowsState {
  entity: SingleEntityState<StatementRows>;
}

const module: Module<StatementRowsState, any> = {
  namespaced: true,
  modules: {
    entity: createSingleEntityModule<StatementRows>(
      {
        apiParamsGetter: ESTATE_INVENTORY_API_PARAMS_GETTER,
      },
      statementRowsApi
    ),
  },
};

export default module;
