import { Module } from 'vuex';

import {
  OwnershipConfig,
  PartyConfig,
  PersonConfig,
} from '@/models';
import * as api from '@/api/config';

import { ConfigState as ChildConfigState, createConfigModule } from './utils';

// TODO: We might wanna move all config values into this state, and only reset when authentication change?
export interface ConfigState {
  person: ChildConfigState<PersonConfig>;
  party: ChildConfigState<PartyConfig>;
  ownership: ChildConfigState<OwnershipConfig>;
}

const module: Module<ConfigState, any> = {
  namespaced: true,
  modules: {
    person: createConfigModule<PersonConfig>(api.person),
    party: createConfigModule<PartyConfig>(api.party),
    ownership: createConfigModule<OwnershipConfig>(api.ownership),
  },
};

export default module;
