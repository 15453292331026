import { Person } from '@/models';

import { client } from './';

const ENDPOINT = '/api/service';

export function sparSearch(personalIdNumber: string) {
  return client
    .get<Person | null>(`${ENDPOINT}/spar`, {
      params: { personalIdNumber },
    })
    .then(response => response.data);
}
