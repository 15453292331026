











































import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';

import {CaseItem} from '@/models';
import {getCaseText} from '@/utils';
import CaseDocumentNavigation
  from "@/components/navigation/CaseDocumentNavigation.vue";

export default Vue.extend({
  components: {CaseDocumentNavigation},
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState('case/current', {caseId: 'id'}),
    ...mapGetters({caseItem: 'case/current/entity'}),
    caseText(): string {
      return getCaseText.bind(this)(this.caseItem as CaseItem | null);
    },
    hasNotes(): boolean {
      return !!this.caseItem?.notes;
    },
    params() {
      const {caseId} = this;
      return {caseId};
    },
  },
});
