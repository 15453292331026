import { instance } from '@/entries/main';
import { SnackbarOptions } from '@/plugins/snackbar';
import { ViewType } from '@/models';
import {number} from "yup";

export type SnackbarEvent =
  | 'load'
  | 'create'
  | 'update'
  | 'delete'
  | 'save'
  | 'put'
  | 'get';

// Helper methods if we don't have acess to the
export function hide() {
  instance.$snackbarHide();
}

export function show(options: Partial<SnackbarOptions>) {
  instance.$snackbar(options);
}

export const LOADING_SNACKBAR: Partial<SnackbarOptions> = {
  text: 'snackbars.loading',
  timeout: -1,
  clear: true,
};

export const LOAD_FAILED_SNACKBAR: Partial<SnackbarOptions> = {
  text: 'snackbars.loadingFailed',
  color: 'error',
  clear: true,
};

export const SAVING_SNACKBAR: Partial<SnackbarOptions> = {
  text: 'snackbars.saving',
  timeout: -1,
};

export const SAVE_SUCCESS_SNACKBAR: Partial<SnackbarOptions> = {
  text: 'snackbars.savingSuccess',
  clear: true,
  color: 'success',
};

export const SAVE_FAILED_SNACKBAR: Partial<SnackbarOptions> = {
  text: 'snackbars.savingFailed',
  clear: true,
  color: 'error',
};

export const DELETE_SUCCESS_SNACKBAR: Partial<SnackbarOptions> = {
  text: 'snackbars.deleteSuccess',
  clear: true,
  color: 'success',
};

export const DELETE_FAILED_SNACKBAR: Partial<SnackbarOptions> = {
  text: 'snackbars.deleteFailed',
  clear: true,
  color: 'error',
};

// when we want more flexibility, otherwise prefer the functions below
export function showSnackbar(
  text: string,
  color: string,
  timeout: number
): Partial<SnackbarOptions> {
  return {
    clear: true,
    color: color,
    text: text,
    timeout: timeout,
  };
}

export function createEntitySnackbar(
  event: SnackbarEvent,
  type: ViewType | 'config',
  options: { success: boolean }
): Partial<SnackbarOptions> {
  const { success } = options;
  const key = success ? 'success' : 'failure';
  return {
    clear: true,
    // NOTE: If we want other colors here, can leave success to null, or allow
    // passing color
    color: success ? 'success' : 'error',
    text: `snackbars.entities.${type}.${event}.${key}`,
  };
}

export function showFailedSnackbar(
  type: ViewType | 'config',
  event?: SnackbarEvent
): (val: any) => void {
  return (val: any) => {
    const success = !!val;
    if (!success) {
      show(createEntitySnackbar(event || 'load', type, { success }));
    }
  };
}
