



















import Vue from 'vue';
import set from 'lodash/set';

import { BEFORE_ROUTE_LEAVE_EVENT } from '@/constants';

export default Vue.extend({
  name: 'Form',
  inject: {
    setAddMore: { default: undefined },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    // indicate if this should validate as true, if only some of the formfields
    // are valid
    partial: {
      type: Boolean,
      default: false,
    },
    disableDirtyCheck: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Object as () => Element | Element[],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      // Form, validation state, etc
      submitted: false,

      // reactive functionality for props to use in v-model
      options: {
        addMore: false,
      },

      // Bound event listeners to confirm navigation if form dirty
      beforeRouteLeaveHandler: undefined as Function | undefined,
      beforeUnloadHandler: undefined as EventListener | undefined,
      // formfields
      formFields: [] as any[],
    };
  },
  mounted() {
    this.beforeRouteLeaveHandler = this.beforeRouteLeave.bind(this);
    this.beforeUnloadHandler = this.beforeUnload.bind(this);
    this.$root.$on(BEFORE_ROUTE_LEAVE_EVENT, this.beforeRouteLeaveHandler);
    window.addEventListener('beforeunload', this.beforeUnloadHandler, false);

    if (this.focus) {
      this.scrollIntoView();
    }
  },
  destroyed() {
    this.$root.$off(BEFORE_ROUTE_LEAVE_EVENT, this.beforeRouteLeaveHandler);
    window.removeEventListener(
      'beforeunload',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.beforeUnloadHandler!,
      false
    );
  },
  provide() {
    const self = this as any;
    return {
      registerFormFields: self.registerFormFields.bind(self),
      deRegisterFormFields: self.deRegisterFormFields.bind(self),
      submit: self.submit.bind(self),
    } as {
      registerFormFields: (instance: any) => void;
      deRegisterFormFields: (instance: any) => void;
      submit: () => void;
    };
  },
  computed: {
    valid(): boolean {
      if (this.partial) {
        return this.formFields
          .filter((f: any) => f.dirty)
          .map((f: any) => f.valid)
          .every(v => v);
      } else {
        return this.formFields.map((f: any) => f.valid).every(v => v);
      }
    },
    dirty(): boolean {
      return this.formFields.map((f: any) => f.dirty).some(v => v);
    },
    formRef(): HTMLElement | undefined {
      let element = this.$refs.form;
      if (Array.isArray(element)) {
        element = element[0];
      }
      return (element as any)?.$el as HTMLElement | undefined;
    },
  },
  methods: {
    validate(): boolean {
      // if there is a reason to validate the vuetify form too, maybe do it here
      //(this.$refs.form as any).validate();
      if (this.partial) {
        return this.formFields
          .filter((f: any) => f.dirty)
          .map((f: any) => f.validate())
          .every(v => v);
      } else {
        return this.formFields.map((f: any) => f.validate()).every(v => v);
      }
    },
    submit(): void {
      this.submitted = true;
      if (this.validate()) {
        const data = this.formFields.reduce(
          (acc: Record<string, any>, current: any) => {
            if (current.path) {
              set(acc, current.path, current.result);
            } else {
              Object.assign(acc, current.result);
            }
            return acc;
          },
          {}
        );
        this.$emit('submit', data);
      }
    },
    reset(): void {
      this.formFields.forEach((f: any) => f.reset());
    },
    clear(): void {
      this.formFields.forEach((f: any) => f.clear());
    },
    beforeRouteLeave(event: any): void {
      if (!this.disableDirtyCheck && this.dirty) {
        event.dirty = true;
      }
    },
    beforeUnload(event: any): void {
      // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (!this.disableDirtyCheck && this.dirty) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      }
    },
    scrollIntoView(): void {
      const { formRef } = this;
      if (formRef) {
        Vue.nextTick(() => {
          let { top, bottom } = formRef.getBoundingClientRect();
          const windowTop = window.scrollY;
          const windowBottom = windowTop + window.innerHeight;
          top += windowTop;
          bottom += windowTop;
          if (top < windowTop || windowBottom < bottom) {
            window.scrollTo({
              top: top - 154,
              behavior: 'smooth',
            });
          }
        });
      }
    },
    registerFormFields(instance: any): void {
      if (!this.formFields.includes(instance)) {
        this.formFields.push(instance);
      }
    },
    deRegisterFormFields(instance: any): void {
      const index = this.formFields.indexOf(instance);
      if (0 <= index) {
        this.formFields.splice(index, 1);
      }
    },
  },
  watch: {
    submitted(val: boolean, oldVal: boolean): void {
      if (val !== oldVal) {
        this.formFields.forEach(f => f.setSubmitted(val));
      }
    },
    'options.addMore'(val: boolean): void {
      // injected is not detected by typescript
      if ((this as any).setAddMore) {
        (this as any).setAddMore(val);
      }
    },
  },
});
