












import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

//import * as sb from '@/utils/snackbar';
import store from '@/plugins/store';

import LoginForm from '@/components/auth/LoginForm.vue';

export default Vue.extend({
  components: {
    LoginForm,
  },
  beforeCreate: () => {
    // we don't wanna display existing auth error from getStatus here
    store.commit('auth/SET_LOAD_ERROR', null);
  },
  computed: {
    ...mapState('auth', ['redirectTo', 'isAuthenticated']),
  },
  methods: {
    ...mapMutations({ setRedirectTo: 'auth/SET_REDIRECT_TO' }),
  },
  watch: {
    isAuthenticated(val: boolean) {
      if (val) {
        let path = this.redirectTo;
        if (path) {
          this.setRedirectTo(null);
        } else {
          path = '/';
        }
        this.$router.replace({ path });
      }
    },
  },
});
