import Vue, { PluginObject } from 'vue';

export const SHOW_SNACKBAR_EVENT = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR_EVENT = 'HIDE_SNACKBAR';

export interface SnackbarOptions {
  text: string;
  color: string;
  timeout: number | string;
  clear: boolean;
}

export interface SnackbarConfig {
  defaults: SnackbarOptions;
}

const defaults: SnackbarOptions = Object.freeze({
  text: '',
  timeout: 5000,
  color: 'info',
  clear: false,
});

declare module 'vue/types/vue' {
  interface Vue {
    $snackbar: (options: Partial<SnackbarOptions>) => void;
    $snackbarHide: () => void;
  }
}

export default {
  install(vue: any, config: SnackbarConfig = { defaults }) {
    const { defaults } = config;

    vue.mixin({
      methods: {
        $snackbar(options: SnackbarOptions): void {
          (this as Vue).$root.$emit(SHOW_SNACKBAR_EVENT, {
            ...defaults,
            ...options,
          });
        },
        $snackbarHide(): void {
          (this as Vue).$root.$emit(HIDE_SNACKBAR_EVENT);
        },
      },
    });
  },
} as PluginObject<SnackbarConfig>;
