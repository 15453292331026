export enum PartyRelationType {
  SPOUSE = 'SPOUSE',
  SIBLING = 'SIBLING',
  CHILD = 'CHILD',
  PARENT = 'PARENT',
  PARTNER = 'PARTNER',
  OTHER_RELATION = 'OTHER_RELATION',
  COUSIN = 'COUSIN',
  CHILD_FROM_PREVIOUS_MARRIAGE = 'CHILD_FROM_PREVIOUS_MARRIAGE',
  EX_SPOUSE = 'EX_SPOUSE',
  HALF_SIBLING = 'HALF_SIBLING',
  PATERNAL_UNCLE = 'PATERNAL_UNCLE',
  MATERNAL_UNCLE = 'MATERNAL_UNCLE',
  PATERNAL_AUNT = 'PATERNAL_AUNT',
  MATERNAL_AUNT = 'MATERNAL_AUNT',
  GRAND_CHILD = 'GRAND_CHILD',
  NIECE = 'NIECE',
  HALF_NIECE = 'HALF_NIECE',
}
