import { Module } from 'vuex';

import { CaseItemParams, EstateInventory } from '@/models';
import { CASE_API_PARAMS_GETTER } from '@/constants';

import {
  getEstateDivisionDocumentUrl
} from '@/api/document';
import { EstateDivision } from '@/models/estate-division/estateDivision.ts'

import {
  createCurrentEntityModule,
  createEntityListModule,
  CurrentEntityState,
  EntityListState,
} from './utils';

import party, { PartyState } from './estate-division/party';
import ownership, { OwnershipState } from './estate-division/ownership';
import ownershipAdjustment, { OwnershipAdjustmentState } from './estate-division/ownership-adjustments';
import documentRows, {
  DocumentRowsState,
} from './estate-inventory/document-rows';
import statementRows, {
  StatementRowsState,
} from './estate-inventory/statement-rows';
import {createApi} from "@/api";

export interface EstateDivisionState {
  list: EntityListState<EstateDivision>;
  current: CurrentEntityState<EstateDivision>;
  party: PartyState;
  ownership: OwnershipState;
  documentRows: DocumentRowsState;
  statementRows: StatementRowsState;
  ownershipAdjustment: OwnershipAdjustmentState;
}

const ENDPOINT = '/api/estates/{caseId}/estate-divisions';

const api = createApi<EstateDivision, CaseItemParams>(ENDPOINT);

const module: Module<EstateDivisionState, any> = {
  namespaced: true,
  modules: {
    list: createEntityListModule<EstateDivision, CaseItemParams>(api, {
      apiParamsGetter: CASE_API_PARAMS_GETTER,
    }),
    current: createCurrentEntityModule<EstateDivision>({
      listStatePath: 'estateDivision.list',
    }),
    // nested modules
    party,
    ownership,
    ownershipAdjustment,
    documentRows,
    statementRows,
  },
  mutations: {
    reset() {}, // eslint-disable-line @typescript-eslint/no-empty-function
  },
  getters: {
    apiParams(
      state: EstateDivisionState,
      getters: any,
      rootState: any,
      rootGetters: any
    ) {
      return {
        ...rootGetters['case/apiParams'],
        estateDivisionId: state.current.id,
      };
    },
    documentUrl(state: EstateDivisionState, getters: any) {
      return getEstateDivisionDocumentUrl(getters['apiParams']);
    },
  },
};

export default module;
