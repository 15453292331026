





































import Vue from 'vue';

export default Vue.extend({
  name: 'Heading',
  props: {
    value: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    leadingIcon: {
      type: String
    },
    largeLeadingIcon: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    window.setTimeout(() => {(this as any).show = true}, 350)
  },
  computed: {
    title() {
      return this.$t(`view.${this.value}.title`);
    },
    hasDescription() {
      return this.$te(`view.${this.value}.description`);
    },
    description() {
      return this.$t(`view.${this.value}.description`);
    },
  },
});
