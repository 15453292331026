




























import Vue from 'vue';

export default Vue.extend({
  name: 'PasswordInputComponent',
  props: {
    value: String,
    label: String,
    icon: String,
    solo: Boolean,
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      required: false,
    },
    allowVisible: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    passwordVisible: false,
  }),
  computed: {
    password: {
      get() {
        return (this as any).value;
      },
      set(val) {
        (this as any).$emit('update:value', val);
      },
    },
    inputType() {
      return (this as any).passwordVisible ? 'text' : 'password';
    },
    inputIcon() {
      return (this as any).passwordVisible
        ? 'mdi-eye-off-outline'
        : 'mdi-eye-outline';
    },
  },
});
