



















import Vue from 'vue';
import {mapState} from 'vuex';

import DeploymentInfo from '@/components/DeploymentInfo.vue';
import {UserRole} from "@/enums/user-role";

export default Vue.extend({
  components: {
    DeploymentInfo,
  },
  computed: {
    ...mapState(['initialized']),
    ...mapState('health', {health: 'data'}),
    ...mapState('auth', {userAccount: 'data'}),
    isSysAdminRole(): boolean {
      return this.userAccount && this.userAccount.role === UserRole.SYSTEM_ADMIN;
    },
    displayDeploymentStatus(): boolean {
      return (
          !!process.env.VUE_APP_GIT_BRANCH &&
          process.env.VUE_APP_GIT_BRANCH === 'master' &&
          this.isSysAdminRole
      );
    },
  },
});
