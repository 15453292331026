















import Vue from 'vue';
import { Schema, ValidationError } from 'yup';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import setWith from 'lodash/setWith';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import hasIn from 'lodash/hasIn';
import toPath from 'lodash/toPath';

import { createDefaultValues, createSchema, Field } from '@/utils/form';

export default Vue.extend({
  inject: ['registerFormFields', 'deRegisterFormFields', 'submit'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array as () => Field<any>[],
      default: () => [] as Field<any>[],
    },
    value: {
      type: Object as () => any,
      required: false,
      default: () => ({} as any),
    },
    path: {
      type: String,
      required: false,
    },
    manualReset: {
      default: false,
    },
  },
  data() {
    let defaultValues = this.fields.reduce((acc, field) => {
      acc[field.name] = field.default;
      return acc;
    }, {} as any);
    defaultValues = createDefaultValues(defaultValues);
    const initialValues = createSchema(this.fields).cast({
      ...defaultValues,
      ...this.value,
    });

    return {
      // Get default values so input does not error, also make values reactive
      defaultValues,
      values: cloneDeep(initialValues),
      // Form, validation state, etc
      errors: {},
      touched: {},
      submitted: false,
    };
  },
  created() {
    (this as any).registerFormFields(this);
    this.$once('hook:beforeDestroy', () => {
      (this as any).deRegisterFormFields(this);
    });
  },
  computed: {
    schema(): Schema<any> {
      return createSchema(this.fields);
    },
    valid(): boolean {
      return Object.keys(this.errors).length == 0;
    },
    dirty(): boolean {
      return !isEqual(this.result, this.initialValues);
    },
    initialValues(): any {
      return this.schema.cast({ ...this.defaultValues, ...this.value });
    },
    result(): any {
      try {
        return cloneDeep(this.schema.cast(this.values));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Failed to cast result', error);
      }
      return {} as any;
    },
  },
  methods: {
    hasError(path: string): boolean {
      return hasIn(this.errors, path);
    },
    errorMessage(path: string): string | undefined {
      return get(this.errors, path);
    },
    unsetError(stringPath: string) {
      const path = toPath(stringPath);
      if (path.length === 1) {
        Vue.delete(this.errors, stringPath);
      } else {
        do {
          const key = path.pop() as string;
          const obj = path.length ? get(this.errors, path) : this.errors;
          Vue.delete(obj, key);
          if (Array.isArray(obj)) {
            // Array's are handled a little bit differently, keeping empty
            // values
            if (!obj.every(isEmpty)) {
              // So if not all values are falsy, break b/c we still ave errors
              break;
            }
          } else if (!isEmpty(obj)) {
            // Regular objects is a bit simpler
            break;
          }
          // If no errors left in object, traverse up and remove empty ones
        } while (path.length);
      }
    },
    resetErrors() {
      Object.keys(this.errors).forEach(f => Vue.delete(this.errors, f));
      // Object.keys(this.errors).forEach(f => unset(this.errors, f));
    },
    setValidationError(error: ValidationError) {
      const { path, message } = error;

      // Hackish way to identify nested paths
      const updatePath = toPath(path);

      if (updatePath.length === 1) {
        Vue.set(this.errors, path, message);
      } else {
        setWith(this.errors, path, message, (nsValue, key, nsObject) => {
          Vue.set(nsObject, key, nsValue);
        });
      }
    },

    // new
    setSubmitted(submitted: boolean) {
      this.submitted = submitted;
    },
    fieldInput(path: string) {
      if (this.submitted || get(this.touched, path)) {
        this.validateField(path);
      }
    },
    validateField(path: string) {
      try {
        setWith(this.touched, path, true, (nsValue, key, nsObject) =>
          Vue.set(nsObject, key, nsValue)
        );

        this.schema.validateSyncAt(path, this.values);
        if (this.hasError(path)) {
          this.unsetError(path);
        }
      } catch (error) {
        this.setValidationError(error);
      }
    },
    validate() {
      this.resetErrors();
      try {
        this.schema.validateSync(this.values, {
          abortEarly: false,
        });
        return true;
      } catch (error) {
        error.inner.map(this.setValidationError.bind(this));
        return false;
      }
    },
    reset() {
      this.resetErrors();
      this.values = cloneDeep(this.initialValues);
      this.$emit('reset');
    },
    clear() {
      this.resetErrors();
      this.values = cloneDeep(this.defaultValues);
    },
    getError(path: string): string | undefined {
      return this.errorMessage(path);
    },
  },
  watch: {
    value(val: any, oldVal: any) {
      if (!this.manualReset && !isEqual(val, oldVal)) {
        // If new data is passed to the form (i.e. it's saved)
        this.reset();
      }
    },
  },
});
