













































import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';
import {EstateInventory, Party, PartyRoleType} from "@/models";
import EstateInventoryRepository from "@/repositories/estateInventoryRepository";
import {PartyDistribution} from "@/models/estatedistribution/partyDistribution";

export default Vue.extend({
  components: {},
  data() {
    return {
      loading: false,
      estateInventory: null as null | EstateInventory,
    }
  },
  computed: {
    ...mapState('case/current', {caseId: 'id'}),
    ...mapState('estateDivision/current', {estateDivisionId: 'id'}),
    params(): object {
      const {caseId, estateDivisionId} = this;
      return {caseId, estateDivisionId};
    },
    isDivisionAllowed(): boolean {
      if(this.estateInventory && this.estateInventory.summary) {
        return this.estateInventory.divisionRequested &&
            (this.estateInventory.summary.hasSpouse || this.estateInventory.summary.hasPartner)
      }
      return true
    }
  },
  mounted() {
    this.getLatestEstateInventory()
  },
  methods: {
    async getLatestEstateInventory() {
      this.loading = true;
      const response = (await EstateInventoryRepository.getLatestEstateInventory(
          this.caseId
      )) as EstateInventory;
      if (response) {
        this.estateInventory = response;
      }
      this.loading = false;
    },
  }
});
