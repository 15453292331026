import {Module} from 'vuex';

import {
  EstateInventoryParams,
  Ownership,
  OwnershipCategory,
  OwnershipOwner, OwnershipType,
} from '@/models';
import {
  ASSET_CATEGORIES,
  ASSET_TYPE_CATEGORIES, ASSET_TYPES,
  ESTATE_INVENTORY_API_PARAMS_GETTER,
  LIABILITY_CATEGORIES,
  OWNERSHIP_OWNERS, OWNERSHIP_TYPES,
} from '@/constants';
import api from '@/api/estate-inventory/ownership';
import {getOwnershipSummary, getOwnershipValue} from '@/utils/ownership';

import {
  createCurrentEntityModule,
  createEntityListModule,
  CurrentEntityState,
  EntityListState,
} from '../utils';

export interface OwnershipState {
  list: EntityListState<Ownership>;
  current: CurrentEntityState<Ownership>;
}

const module: Module<OwnershipState, any> = {
  namespaced: true,
  modules: {
    list: createEntityListModule<Ownership, EstateInventoryParams>(api, {
      apiParamsGetter: ESTATE_INVENTORY_API_PARAMS_GETTER,
    }),
    current: createCurrentEntityModule<Ownership>({
      listStatePath: 'estateInventory.ownership.list',
    }),
  },
  getters: {
    apiParams(state: any, rootState: any, rootGetters: any) {
      return {
        ...rootGetters[ESTATE_INVENTORY_API_PARAMS_GETTER],
      };
    },
    byOwner(state: any): Record<OwnershipOwner, Ownership[]> {
      const result = {} as Record<OwnershipOwner, Ownership[]>;
      for (const owner of OWNERSHIP_OWNERS) {
        result[owner] = state.list.entities.filter(
          (e: Ownership) => e.owner === owner
        );
      }

      return result;
    },
    byCategory(state: any, getters: any) {
      const result = {...getters['byOwner']};
      for (const owner in result) {
        const cats = {} as Record<OwnershipCategory, Ownership[]>;
        for (const cat of ASSET_TYPE_CATEGORIES) {
          cats[cat] = result[owner].filter(
            (e: Ownership) => e.assetCategory === cat
          );
        }
        result[owner] = cats;
      }

      return result;
    },
    totals(state: any, getters: any, rootState: any) {
      const entities = getters['byCategory'] as Record<OwnershipOwner,
        Record<OwnershipCategory, Ownership[]>>;
      const result = {} as Record<OwnershipOwner,
        Record<OwnershipCategory, number>>;
      for (const o in entities) {
        const owner = o as OwnershipOwner;
        result[owner] = {} as Record<OwnershipCategory, number>;
        let total = 0;
        let assets = 0;
        let liabilities = 0;

        //Summarize assets by type.
        const ownershipSummaries = getOwnershipSummary(entities, owner, rootState);

        for (const c in entities[owner as OwnershipOwner]) {
          const cat = c as OwnershipCategory;
          const value = (entities[owner][cat] as Ownership[])
            .map(e =>
              getOwnershipValue(
                e,
                rootState.config.ownership.data?.defaultCurrency
              )
            )
            .reduce<number>(
              (total: number, current: number) => total + current,
              0
            );
          result[owner][cat] = value;
          total += value;
          if (ASSET_CATEGORIES.includes(cat as any)) {
            assets += value;
          } else if (LIABILITY_CATEGORIES.includes(cat as any)) {
            liabilities += value;
          }
        }
        Object.assign(result[owner], {
          total,
          assets,
          liabilities,
          ownershipSummaries: ownershipSummaries
        });
      }

      return result;
    },
    deceasedBankAccounts(state: any, getters: any) {
      return getters.byCategory.DECEASED.BANK_ACCOUNT;
    },
  },
};

export default module;
