import { render, staticRenderFns } from "./Heading.vue?vue&type=template&id=7a739f13&scoped=true&"
import script from "./Heading.vue?vue&type=script&lang=ts&"
export * from "./Heading.vue?vue&type=script&lang=ts&"
import style0 from "./Heading.vue?vue&type=style&index=0&id=7a739f13&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a739f13",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardText,VCardTitle,VIcon,VSnackbar,VSpacer})
