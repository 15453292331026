import Vue, { PluginObject } from 'vue';

export const SHOW_DIALOG_EVENT = 'SHOW_DIALOG';

export interface DialogOptions {
  persistent?: boolean;
  confirm?: boolean;
  title?: string;
  text?: string;
  additionalText?: string;
  color?: string;
}

export type DialogArguments<T = any> = DialogOptions & {
  callback: (result?: T) => void;
};

export type DialogParameters = Omit<DialogOptions, 'confirm'>;
export type ConfirmParametrs = Omit<DialogParameters, 'persistent'>;

export interface DialogConfig {
  defaults: Partial<DialogOptions>;
  confirmDefaults: Partial<Omit<DialogOptions, 'confirm'>>;
}

const defaults: Partial<DialogOptions> = Object.freeze({
  persistent: false,
});

const confirmDefaults: Partial<Omit<DialogOptions, 'confirm'>> = Object.freeze({
  title: 'dialog.confirm.title',
  color: 'warning',
});

declare module 'vue/types/vue' {
  interface Vue {
    $dialog<T>(options: DialogParameters): Promise<T>;
    $confirm(
      options: Omit<DialogParameters, 'confirm' | 'persistent'>
    ): Promise<boolean>;
  }
}

export default {
  install(vue: any, config: DialogConfig = { defaults, confirmDefaults }) {
    const { defaults, confirmDefaults } = config;

    vue.mixin({
      methods: {
        $dialog<T>(options: DialogParameters): Promise<T> {
          return new Promise(callback =>
            (this as Vue).$root.$emit(SHOW_DIALOG_EVENT, {
              ...defaults,
              ...options,
              callback,
              confirm: false,
            })
          );
        },
        $confirm(options: ConfirmParametrs): Promise<boolean> {
          return new Promise<boolean>(callback =>
            (this as Vue).$root.$emit(SHOW_DIALOG_EVENT, {
              ...defaults,
              ...confirmDefaults,
              ...options,
              callback,
              persistent: true,
              confirm: true,
            })
          );
        },
      },
    });
  },
} as PluginObject<DialogConfig>;
