var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-list',{staticClass:"nav h-100 d-flex flex-column",attrs:{"dense":"","nav":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',[_c('initials-avatar-component',{attrs:{"firstname":_vm.userAccount.firstname,"lastname":_vm.userAccount.surname,"size":34}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"caption grey--text"},[_vm._v("Inloggad som:")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.userAccount.fullname)+" ")]),_c('v-list-item-subtitle',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.userAccount.office.name)+" ")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":{ name: 'caseList' },"exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('route.home')))])],1)],1),_vm._l((_vm.recentCaseItems),function(item,i){return [(i === 0)?_c('v-divider',{key:("top_divider_" + i),staticClass:"my-1"}):_vm._e(),(!!item)?_c('v-list-group',{key:("case_item_" + (item.id)),attrs:{"group":_vm.getCaseRoute(item.id)},on:{"click":function($event){return _vm.$router.push({ name: 'case', params: { caseId: item.id } })}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getCaseText(item))+" ")])]},proxy:true},{key:"appendIcon",fn:function(){return undefined},proxy:true}],null,true)},[(_vm.caseItemHasEstateInventories(item))?_vm._l((item.estateInventories),function(doc){return _c('v-list-item',{key:doc.id,staticClass:"pl-5",class:_vm.estateInventoryId == doc.id ? 'v-list-item--active' : '',attrs:{"to":{
              name: 'estateInventory',
              params: { caseId: _vm.caseId, estateInventoryId: doc.id },
            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-list-bulleted")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(_vm.CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_INVENTORY']))+" "+_vm._s(!doc.proceeding || !doc.proceeding.startDate ? '' : doc.proceeding.startDate)+" ")])],1)}):_vm._e(),(_vm.caseItemHasEstateDivisions(item))?_vm._l((item.estateDivisions),function(doc){return _c('v-list-item',{key:doc.id,staticClass:"pl-5",class:_vm.estateDivisionId == doc.id ? 'v-list-item--active' : '',attrs:{"to":{
              name: 'estateDivision',
              params: { caseId: _vm.caseId, estateDivisionId: doc.id },
            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-split-vertical")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(_vm.CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_DIVISION']))+" ")])],1)}):_vm._e(),(_vm.caseItemHasEstateDistributions(item))?_vm._l((item.estateDistributions),function(doc){return _c('v-list-item',{key:doc.id,staticClass:"pl-5",class:_vm.estateDistributionId == doc.id ? 'v-list-item--active' : '',attrs:{"to":{
              name: 'estateDistribution',
              params: { caseId: _vm.caseId, estateDistributionId: doc.id },
            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-call-split")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(_vm.CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_DISTRIBUTION']))+" ")])],1)}):_vm._e(),_c('v-list-item',{staticClass:"pl-5",on:{"click":function($event){return _vm.createDocument(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('form.generic.add'))+"... ")])],1)],2):_vm._e()]}),_c('v-divider',{staticClass:"mt-auto mb-1"}),_c('v-list-item',{on:{"click":_vm.onLogout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('auth.logout')))])],1)],1)],2),(_vm.showCreateForm)?_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.createFormVisible),callback:function ($$v) {_vm.createFormVisible=$$v},expression:"createFormVisible"}},[_c('v-card',{staticClass:"pa-5"},[_c('CreateCaseDocumentForm',{attrs:{"case-id":_vm.selectedCaseId},on:{"close":function($event){_vm.createFormVisible = false},"created":_vm.onCreate}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }