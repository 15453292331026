






import Vue from 'vue';

import { Person } from '@/models';
import { sparSearch } from '@/api/service';
import { flattenPerson } from '@/utils';
import {
  isPidNumber,
  validatePidYearAndDate,
  validateLuhn,
} from '@/utils/validation';

export default Vue.extend({
  name: 'SparSearch',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      boundSearch: null as any,
    };
  },
  created() {
    this.boundSearch = this.searchAndSet.bind(this);
  },
  methods: {
    validatePersonalIdNumber(personalIdNumber: string): boolean {
      let error = null;
      if (!isPidNumber(personalIdNumber)) {
        error = 'format';
      } else if (!validatePidYearAndDate(personalIdNumber)) {
        error = 'date';
      } else if (!validateLuhn(personalIdNumber.substring(2))) {
        error = 'luhn';
      }

      if (error) {
        this.$snackbar({
          text: `form.validation.pid.${error}`,
          color: 'error',
          clear: true,
        });
        return false;
      }
      return true;
    },
    async search(personalIdNumber: string): Promise<Person | null> {
      this.loading = true;
      let data = null;
      try {
        data = await sparSearch(personalIdNumber);
      } catch (e) {
        if (e.response?.status !== 404) {
          // eslint-disable-next-line no-console
          console.warn(e);
          this.$snackbar({
            text: 'service.spar.error',
            color: 'error',
            clear: true,
          });
          return null;
        }
      } finally {
        this.loading = false;
      }
      if (!data) {
        this.$snackbar({
          text: 'service.spar.notFound',
          color: 'warning',
          clear: true,
        });
      }
      return data;
    },
    async searchAndSet(
      personalIdNumber: string,
      values: Partial<Person>,
      cb?: (success: boolean) => void
    ) {
      if (this.disabled) {
        return;
      }

      const pid = personalIdNumber.replace(/\D/g, '');
      if (!this.validatePersonalIdNumber(pid)) {
        return;
      }

      const data = await this.search(pid);
      if (data) {
        const prevId = values.id
        Object.assign(values, flattenPerson(data));
        values.id = prevId
        this.$snackbar({
          text: 'service.spar.success',
          color: 'success',
          clear: true,
        });
      }
      if (cb) {
        cb(!!data);
      }
    },
  },
});
