import { BaseModel, Params } from '@/models';
import { Module } from 'vuex';

import { createLoadingModule, LoadingState } from './loading-module';
import { createSavingModule, SavingState } from './saving-module';

export type SingleEntityState<T extends BaseModel> = LoadingState &
  SavingState & {
    entity: T | null;
  };

export function createSingleEntityModule<T extends BaseModel>(
  options: {
    apiParamsGetter: string;
  },
  api: {
    load: (params: Params) => Promise<T>;
    save: (params: Params, entity: Partial<T>) => Promise<T>;
  }
): Module<SingleEntityState<T>, any> {
  const loadModule = createLoadingModule();
  const saveModule = createSavingModule();

  return {
    namespaced: true,
    state: {
      ...(loadModule.state as LoadingState),
      ...(saveModule.state as SavingState),
      entity: null,
    },
    mutations: {
      ...loadModule.mutations,
      ...saveModule.mutations,
      SET_ENTITY(state: SingleEntityState<T>, entity: T | null) {
        state.entity = entity;
      },
    },
    getters: {
      isLoadingOrSaving: (state: SingleEntityState<T>) =>
        state.isLoading || state.isSaving,
    },
    actions: {
      async load({ commit, rootGetters }) {
        // TODO: Take root stae here, to figure out what params should be?
        commit('SET_LOADING', true);
        try {
          const data = await api.load(rootGetters[options.apiParamsGetter]);
          commit('SET_ENTITY', data);
          commit('SET_LOAD_ERROR', null);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          commit('SET_LOAD_ERROR', error);
          return false;
        } finally {
          commit('SET_LOADING', false);
        }
        return true;
      },
      async save({ commit, rootGetters }, entity: Partial<T>) {
        commit('SET_SAVING', true);
        try {
          const data = await api.save(
            rootGetters[options.apiParamsGetter],
            entity
          );
          commit('SET_ENTITY', data);
          commit('SET_SAVE_ERROR', null);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          commit('SET_SAVE_ERROR', error);
          return false;
        } finally {
          commit('SET_SAVING', false);
        }
        return true;
      },
    },
  };
}
