




















import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import {
  SHOW_SNACKBAR_EVENT,
  HIDE_SNACKBAR_EVENT,
  SnackbarOptions,
} from '@/plugins/snackbar';

export default Vue.extend({
  name: 'Snackbar',
  data() {
    return {
      visible: false,
      transitioning: false,
      showSnackbarHandler: undefined as undefined | Function,
      hideSnackbarHandler: undefined as undefined | Function,
      items: [] as SnackbarOptions[],
    };
  },
  created() {
    this.showSnackbarHandler = this.showSnackbar.bind(this);
    this.hideSnackbarHandler = this.hideSnackbar.bind(this);
    this.$root.$on(SHOW_SNACKBAR_EVENT, this.showSnackbarHandler);
    this.$root.$on(HIDE_SNACKBAR_EVENT, this.hideSnackbarHandler);
  },
  destroyed() {
    this.$root.$off(SHOW_SNACKBAR_EVENT, this.showSnackbarHandler);
    this.$root.$off(HIDE_SNACKBAR_EVENT, this.hideSnackbarHandler);
  },
  methods: {
    showSnackbar(options: SnackbarOptions) {
      if (options.clear) {
        // If snackbars should be cleared, replace items with new options
        const items = [options];
        if (this.items.length) {
          // If snackbar exists, we need to keep the current one to animate
          // properly
          items.unshift(this.items[0]);
        }
        this.items = items;
        this.visible = false;
      } else {
        this.items.push(options);
      }
    },
    hideSnackbar() {
      this.visible = false;
    },
    close() {
      this.visible = false;
    },
  },
  computed: {
    options(): SnackbarOptions | undefined {
      if (this.items.length) {
        return this.items[0];
      }
      return undefined;
    },
    text(): TranslateResult | undefined {
      if (this.options?.text) {
        return this.$t(this.options.text);
      }
      return undefined;
    },
    shouldShow(): boolean {
      return !this.visible && !!this.items.length && !this.transitioning;
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.transitioning = true;
        // The snackbar leave animation is 150ms, so we want to wait for it to
        // finish before removing snackbar
        setTimeout(() => {
          this.transitioning = false;
          this.items.shift();
          if (this.shouldShow) {
            this.visible = true;
          }
        }, 150);
      }
    },
    options(val) {
      if (this.shouldShow) {
        // Only set visible if false and no current snackbars
        this.visible = true;
      } else if (!val && this.visible) {
        this.visible = false;
      }
    },
  },
});
