































































import Vue from 'vue';
import { mapMutations, mapState, mapGetters } from 'vuex';
import { SAVE_SUCCESS_SNACKBAR } from '@/utils/snackbar';

import { getCaseText } from '@/utils';
import { CASE_DOCUMENT_TYPE_TRANSLATIONS } from '@/constants';
import {CaseDocumentType, EstateInventory} from '@/models';

import ErrorAlert from '@/components/alerts/ErrorAlert.vue';
import EstateInventoryRepository from "@/repositories/estateInventoryRepository";
import EstateDivisionRepository from "@/repositories/estateDivisionRepository";
import {EstateDivision} from "@/models/estate-division/estateDivision";

export default Vue.extend({
  name: 'CreateCaseDocumentForm',
  components: {
    ErrorAlert,
  },
  props: {
    caseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      existingEstateInventory: null as EstateInventory | null,
      existingEstateDivision: null as EstateDivision | null,
      CASE_DOCUMENT_TYPE_TRANSLATIONS,
      disabled: false,
      disableDirtyCheck: false,
      icons: {
        estateInventory: 'mdi-format-list-bulleted',
        estateDivision: 'mdi-call-split',
        estateDistribution: 'mdi-scale-balance',
        undecided: 'mdi-file-document-outline',
        documents: 'mdi-file-document-outline',
      }
    };
  },
  computed: {
    ...mapState('case', ['saveError', 'isSaving']),
    ...mapGetters({ caseItem: 'case/current/entity' }),
    hasExistingEstateInventory(): boolean{
      return !!this.existingEstateInventory;
    },
    hasExistingEstateDivision(): boolean{
      return !!this.existingEstateDivision;
    },
  },
  methods: {
    async getLatestEstateInventory(): Promise<EstateInventory | null> {
      const response = (await EstateInventoryRepository.getLatestEstateInventory(
        this.caseId
      )) as EstateInventory;
      if (response) {
        this.existingEstateInventory = response;
      }
      return null
    },
    async getLatestEstateDivision(): Promise<EstateDivision | null> {
      const response = (await EstateDivisionRepository.getLatestEstateDivision(
        this.caseId
      )) as EstateDivision;
      if (response) {
        this.existingEstateDivision = response;
      }
      return null
    },
    ...mapMutations('case', { setSaveError: 'SET_SAVE_ERROR' }),
    getCaseText,
    async onCreate(type: CaseDocumentType, createAllDocumentTypes: boolean) {
      const { caseId } = this;
      const data = await this.$store.dispatch('case/createDocument', {
        params: { caseId },
        data: { type: type, createAllDocumentTypes: createAllDocumentTypes },
      });
      if (data) {
        this.$snackbar({
          ...SAVE_SUCCESS_SNACKBAR,
          text: 'form.caseItemDocument.snackbar.success',
        });
        this.$emit('created', data, type);
      }
    },
    onCancel() {
      this.setSaveError(null);
      this.$emit('close');
    },
  },
  async mounted(){
    await this.getLatestEstateInventory();
    await this.getLatestEstateDivision();
  }
});
