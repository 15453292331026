import axios from 'axios';
import qs from 'qs';

import store from '@/plugins/store';
import { BaseModel, Health, Params } from '@/models';

export const BASE_URL = process.env.VUE_APP_API_BASE_URL;

function transformResponse(data: string): any {
  if (data) {
    return JSON.parse(data, (key, value) => {
      if (
        typeof value === 'string' &&
        (key === 'createdAt' ||
          key == 'updatedAt' ||
          /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(Z|([+-])(\d{2}):(\d{2}))$/.test(
            value
          ))
      ) {
        return new Date(value);
      }
      return value;
    });
  }
  return null;
}

function paramsSerializer(params: any): string {
  return qs.stringify(params, { skipNulls: true, arrayFormat: 'repeat' });
}

export const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: 10000,
  transformResponse,
  paramsSerializer,
  headers: {
    Accept: 'application/json',
  },
});

client.interceptors.response.use(
  res => res,
  (error: any) => {
    const { response } = error;
    // if we get a 401 when the user is authenticated we'll set them as
    // unauthetnicated
    if (response.status == 401 && store.state.auth.isAuthenticated) {
      store.commit('auth/SET_AUTHENTICATED', false);
    }

    return Promise.reject(error);
  }
);

export function getHealth() {
  return client.get<Health>('/health').then(response => response.data);
}

export const createEndpoint = (endpointTemplate: string, params: Params = {}) =>
  // Turn it into a string here since we can pass number
  Object.entries(params).reduce<string>(
    (url, [key, value]) => url.replace(`{${key}}`, `${value}`),
    endpointTemplate as any
  );

export function createSingleApi<T extends BaseModel, P extends Params = any>(
  endpointTemplate: string
) {
  return {
    load(params: Params) {
      return client
        .get<T>(createEndpoint(endpointTemplate, params))
        .then(response => response.data);
    },
    save(params: Params, entity: Partial<T>) {
      return client
        .put<T>(createEndpoint(endpointTemplate, params), entity)
        .then(response => response.data);
    },
  };
}

export function createApi<T extends BaseModel, P extends Params = any>(
  endpointTemplate: string
) {
  return {
    list(pathParams?: Params, params?: Record<string, any>) {
      return client
        .get<T[]>(createEndpoint(endpointTemplate, pathParams), { params })
        .then(response => response.data);
    },
    get(params: Params, id: T['id']) {
      return client
        .get<T>(`${createEndpoint(endpointTemplate, params)}/${id}`)
        .then(response => response.data);
    },
    create(params: Params, entity: Partial<T>) {
      return client
        .post<T>(createEndpoint(endpointTemplate, params), entity)
        .then(response => response.data);
    },
    update(params: Params, entity: Partial<T>) {
      const { id } = entity;
      return client
        .put<T>(`${createEndpoint(endpointTemplate, params)}/${id}`, entity)
        .then(response => response.data);
    },
    remove(params: Params, id: T['id']) {
      return client
        .delete<void>(`${createEndpoint(endpointTemplate, params)}/${id}`)
        .then(() => id);
    },
  };
}

export function createConfigApi<T>(endpoint: string) {
  return {
    load() {
      return client.get<T>(endpoint).then(response => response.data);
    },
  };
}
