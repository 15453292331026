import { Module } from 'vuex';

export interface LoadingState {
  isLoading: boolean;
  loadError: Error | null;
  initialLoad: boolean;
}

export function createLoadingModule(): Module<LoadingState, any> {
  return {
    state: {
      isLoading: false,
      loadError: null,
      initialLoad: true,
    },
    mutations: {
      SET_LOADING(state: LoadingState, loading: boolean) {
        if (state.initialLoad && state.isLoading && !loading) {
          state.initialLoad = false;
        }
        state.isLoading = loading;
      },
      SET_LOAD_ERROR(state: LoadingState, error: Error | null) {
        state.loadError = error;
        // TODO: Set error message here?
      },
      SET_INITIAL_LOAD(state: LoadingState, initialLoad: boolean) {
        state.initialLoad = initialLoad;
      },
    },
  };
}
