import {
  CaseItemParams,
  DocumentTemplate,
  EstateDistributionParams,
  EstateDivisionParams,
  EstateInventoryParams
} from '@/models';
import {BASE_URL, client, createEndpoint} from './';
import {DocumentType} from "@/enums/document-type";

const DOCUMENTS_ENDPOINT = '/api/documents';
const DOCUMENT_TEMPLATES_ENDPOINT = '/api/document-templates';
const ESTATES_ENDPOINT = '/api/estates/{caseId}';
const ESTATE_INVENTORIES_ENDPOINT = '/api/estate-inventories';
const ESTATE_DIVISIONS_ENDPOINT = '/api/estate-divisions';
const ESTATE_DISTRIBUTIONS_ENDPOINT = '/api/estate-distributions';

export function getTransactionsUrl(params: CaseItemParams): string {
    return `${BASE_URL}${createEndpoint(ESTATES_ENDPOINT, params)}/documents/transactions`;
}

export function getEstateInventoryDocumentUrl(params: EstateInventoryParams): string {
    return `${BASE_URL}${createEndpoint(
        ESTATE_INVENTORIES_ENDPOINT,
        params
    )}/${params.estateInventoryId}/documents`;
}

export function getEstateDivisionDocumentUrl(params: EstateDivisionParams): string {
  return `${BASE_URL}${createEndpoint(
    ESTATE_DIVISIONS_ENDPOINT,
    params
  )}/${params.estateDivisionId}/documents`;
}

export function getEstateDistributionDocumentUrl(params: EstateDistributionParams): string {
  return `${BASE_URL}${createEndpoint(
    ESTATE_DISTRIBUTIONS_ENDPOINT,
    params
  )}/${params.estateDistributionId}/documents`;
}

export function getSummonsDocumentUrl(params: EstateInventoryParams): string {
  return getEstateInventoryDocumentUrl(params) + `/summons`;
}

export function getDocumentTemplates<T>(documentType: DocumentType): Promise<T | Array<DocumentTemplate> | string[]> {
    return client
        .get<string[]>(`${DOCUMENT_TEMPLATES_ENDPOINT}?documentType=${documentType}`)
        .then(response => response.data);
}

export function getFormats() {
    return client
        .get<string[]>(`${DOCUMENTS_ENDPOINT}/formats`)
        .then(response => response.data);
}
