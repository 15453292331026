

























import Vue from 'vue';
import {mapState} from 'vuex';

import {EstateInventory, ModificationTrackedModel} from '@/models';
import store from "@/plugins/store";

export default Vue.extend({
  name: 'CaseDocumentNavigationSegment',
  components: {},
  props: {
    documents: {
      type: Array as () => ModificationTrackedModel[],
      default: () => [] as ModificationTrackedModel[],
    },
    name: {
      type: String,
      default: 'estateInventory',
    },
    title: {
      type: String,
      default: '',
    },
    dateTitle: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('case/current', {caseId: 'id'}),
  },
  methods: {
    getDocumentParams(id: number) {
      const params: any = {caseId: this.caseId};
      const documentIdentifierName = this.name + 'Id';
      params[documentIdentifierName] = id;
      return params;
    },
    getTitleText(document: any) {
      if (document && document.proceeding && document.proceeding.startDate) {
        return document.proceeding.startDate;
      }
      return '';
    },
    selectDocument(document: ModificationTrackedModel) {
      this.$emit('selected', document);
    },
  },
});
