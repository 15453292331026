import {Module} from 'vuex';
import {getFormats} from '@/api/document';

import {createLoadingModule, LoadingState} from './utils';
import {DocumentType} from "@/enums/document-type";

const loadModule = createLoadingModule();

export interface DocumentState extends LoadingState {
    formats: string[] | null;
    activeDocumentType: DocumentType | null
}

const initialState: DocumentState = {
    activeDocumentType: null,
    formats: null,
    ...(loadModule.state as LoadingState),
};

const module: Module<DocumentState, any> = {
    namespaced: true,
    state: {...initialState},
    mutations: {
        ...loadModule.mutations,
        SET_FORMATS(state: DocumentState, formats: string[] | null) {
            state.formats = formats;
        },
        updateActiveDocumentType(state, documentType: DocumentType) {
            state.activeDocumentType = documentType
        },
    },
    actions: {
        async getFormats({commit}) {
            commit('SET_LOADING', true);
            try {
                const data = await getFormats();
                commit('SET_FORMATS', data);
                return true;
            } catch (error) {
                console.error(error);
                commit('SET_LOAD_ERROR', 'loading.networkError');
            } finally {
                commit('SET_LOADING', false);
            }
            return false;
        },
        getActiveDocumentType() {
            return this.state.activeDocumentType;
        }
    },
};

export default module;
