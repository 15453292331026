import {deleteRequest, ENDPOINTS, get, put} from "@/api/mainApi";
import {showFailedSnackbar} from "@/utils/snackbar";
import {EstateDivision} from "@/models/estate-division/estateDivision";

async function getEstateDivisionById(
    caseId: number,
    estateDivisionId: number,
): Promise<EstateDivision | null> {
    try {
        return await get(
            ENDPOINTS.ESTATES +
            '/' +
            caseId +
            '/' +
            ENDPOINTS.ESTATE_DIVISIONS +
            '/' +
            estateDivisionId
        )
    } catch (e) {
        showFailedSnackbar('estateDivision', 'get');
    }
    return null;
}

async function updateEstateDivision(
  caseId: number,
  estateDivisionId: number,
  estateDivision: EstateDivision
): Promise<EstateDivision | null> {
  try {
    return await put(
      ENDPOINTS.ESTATES +
      '/' +
      caseId +
      '/' +
      ENDPOINTS.ESTATE_DIVISIONS +
      '/' +
      estateDivisionId,
      estateDivision
    );
  } catch (e) {
    showFailedSnackbar('estateDivision', 'update');
  }
  return null;
}

async function getLatestEstateDivision(
    caseId: number,
): Promise<EstateDivision | null> {
    try {
        return await get(
            ENDPOINTS.ESTATES +
            '/' +
            caseId +
            '/' +
            ENDPOINTS.ESTATE_DIVISIONS +
            '/' +
            ENDPOINTS.LATEST
        )
    } catch (e) {
        showFailedSnackbar('estateInventory', 'get');
    }
    return null;
}

async function deleteById(
  caseId: number,
  estateDivisionId: number
): Promise<string> {
  try {
    return await deleteRequest(
      ENDPOINTS.ESTATES +
      '/' +
      caseId +
      '/' +
      ENDPOINTS.ESTATE_DIVISIONS +
      '/' +
      estateDivisionId,
    )
  } catch (e) {
    showFailedSnackbar('estateDivision', 'delete');
  }
  return '';
}

export default {
  getEstateDivisionById,
  updateEstateDivision,
  getLatestEstateDivision,
  deleteById
}
