






import Vue from 'vue';
import { mapState } from 'vuex';

// TODO: Do we wanna keep something here? Or maybe this is superflous?
export default Vue.extend({
  name: 'Case',
  components: {},
  computed: {
    ...mapState('case/current', { caseId: 'id' }),
    key(): string {
      return `case_${this.caseId}`;
    },
  },
});
