
























































import Vue from 'vue';

export default Vue.extend({
  name: 'FormActions',
  components: {},
  props: {
    // All actions disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    // indicate is saving
    saving: {
      type: Boolean,
      default: false,
    },
    // If form is valid, will enable save/add button
    valid: {
      type: Boolean,
      default: true,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
    // if the parent form is wrapped in a dialog
    dialogMode: {
      type: Boolean,
      default: false,
    },
    // Whether or not addMode should be enabled
    addMore: {
      type: Boolean,
      default: false,
    },
    // Force hiding add more checkbox
    hideAddMore: {
      type: Boolean,
      default: false,
    },
    // Update means that it should show save instead of add, show reset,
    // hide addMore, etc.
    update: {
      type: Boolean,
      default: false,
    },
    // Hide cancel button
    hideCancel: {
      type: Boolean,
      default: false,
    },
    // Force show cancel button
    showCancel: {
      type: Boolean,
      default: false,
    },
    // Force show reset button
    showReset: {
      type: Boolean,
      default: false,
    },
    // Force hide reset button
    hideReset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addMoreSync: {
      get(): boolean {
        return this.addMore;
      },
      set(val: boolean) {
        this.$emit('update:addMore', val);
      },
    },
  },
  methods: {
    onSave() {
      this.$emit('save');
    },
    onAdd() {
      this.$emit('add');
    },
  },
});
