import Vue from 'vue';
import VueRouter, {Route, RouteConfig} from 'vue-router';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';

import store from './store';
// Top level routes
import Login from '@/views/external/Login.vue';
import App from '@/apps/App.vue';
// App/case routes
import CaseList from '@/views/Case/CaseList.vue';
import Case from '@/views/Case/Case.vue';

Vue.use(VueRouter);

interface RouteLink {
  text: string;
  icon: string;
  hasValue?: boolean;
  children?: RouteInfo[];
}

export type RouteInfo = RouteLink & RouteConfig;

// TODO: Remove all extra values, when added into navigation drawer
export const routes: Array<RouteInfo | Partial<Route>> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
    },
  },
  {
    path: '/',
    name: 'home',
    component: App,
    redirect: {name: 'caseList'},
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/arenden',
        name: 'caseList',
        component: CaseList,
      } as any,
      {
        path: '/arenden/:caseId',
        name: 'case',
        component: Case,
        redirect: {name: 'deceased'},
        children: [
          {
            path: 'avliden',
            name: 'deceased',
            text: 'route.deceased',
            component: () =>
              import(
                /* webpackChunkName: "case-item" */ '@/views/Case/Deceased.vue'
                ),
          },
          {
            path: 'kontantredovisning/:transactionId?',
            name: 'transactions',
            component: () =>
              import(
                /* webpackChunkName: "case-item" */ '@/views/Case/Transactions.vue'
                ),
          },
          {
            path: 'anteckningar',
            name: 'notes',
            text: 'route.notes',
            component: () =>
              import(
                /* webpackChunkName: "case-item" */ '@/views/Case/Notes.vue'
                ),
          },
          {
            path: 'bouppteckning/:estateInventoryId',
            name: 'estateInventory',
            redirect: {name: 'dashboard'},
            component: () =>
              import(
                /* webpackChunkName: "estate-division" */ '@/views/EstateInventory/EstateInventory.vue'
                ),
            children: [
              {
                path: '',
                name: 'dashboard',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */
                    '@/views/EstateInventory/Dashboard.vue'
                    ),
              },
              {
                path: 'mallredigeraren',
                name: 'template-editor',
                text: 'route.templateEditor',
                component: () =>
                  import(
                    /* webpackChunkName: "case-item" */ '@/views/Document/DocumentTemplateEditor.vue'
                    ),
              },
              {
                path: 'delagare/:partyId?',
                name: 'parties',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-inventory" */ '@/views/EstateInventory/Parties.vue'
                    ),
              },
              {
                path: 'innehav/:ownershipId?',
                name: 'ownership',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateInventory/Ownership.vue'
                    ),
              },
              {
                path: 'handlingar',
                name: 'document-list',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateInventory/DocumentRows.vue'
                    ),
              },
              {
                path: 'kallelse',
                name: 'summons',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateInventory/Summons.vue'
                    ),
              },
              {
                path: 'narvaro',
                name: 'representation',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateInventory/Representation.vue'
                    ),
              },
              {
                path: 'att-satser',
                name: 'statements',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateInventory/Statements.vue'
                    ),
              },
            ],
          },
          {
            path: 'bodelning/:estateDivisionId',
            name: 'estateDivision',
            redirect: {name: 'overview'},
            component: () =>
              import(
                /* webpackChunkName: "case-item" */
                '@/views/EstateInventory/EstateInventory.vue'),
            children: [
              {
                path: '',
                name: 'overview',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */
                    '@/views/EstateDivision/Overview.vue'
                    ),
              },
              {
                path: 'mallredigeraren',
                name: 'template-editor-division',
                text: 'route.templateEditor',
                component: () =>
                  import(
                    /* webpackChunkName: "case-item" */ '@/views/Document/DocumentTemplateEditor.vue'
                    ),
              },
              {
                path: 'innehav',
                name: 'estateDivisionOwnership',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */
                    '@/views/EstateDivision/CreateEstateDivisonOwnership.vue'
                    ),
              },
              {
                path: 'innehav/:ownershipId?',
                name: 'editEstateDivisionOwnership',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateDivision/Ownership.vue'
                    ),
              },
              {
                path: 'justeringar',
                name: 'adjustments',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */
                    '@/views/EstateDivision/EstateAdjustmentOverview.vue'
                    ),
              },
              {
                path: 'fordelning',
                name: 'estateDivisionDistribution',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */
                    '@/views/EstateDivision/Distribution.vue'
                    ),
              },
              {
                path: 'tidigare',
                name: 'previousEstateDivisions',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */
                    '@/views/EstateDivision/PreviousDivisionsOverview.vue'
                    ),
              },
              {
                path: 'att-satser',
                name: 'estateDivisionStatements',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateDivision/Statements.vue'
                    ),
              },
              {
                path: 'handlingar',
                name: 'estateDivisionDocumentList',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-division" */ '@/views/EstateDivision/DocumentRows.vue'
                    ),
              },
            ],
          },
          {
            path: 'arvskifte/:estateDistributionId',
            name: 'estateDistribution',
            redirect: {name: 'edOverview'},
            component: () =>
              import(
                /* webpackChunkName: "case-item" */
                '@/views/EstateInventory/EstateInventory.vue'), // MUST BE EstateInventory.vue!!
            children: [
              {
                path: '',
                name: 'edOverview',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-distribution" */
                    '@/views/EstateDistribution/Overview.vue'
                    ),
              },
              {
                path: 'mallredigeraren',
                name: 'template-editor-distribution',
                text: 'route.templateEditor',
                component: () =>
                  import(
                    /* webpackChunkName: "case-item" */ '@/views/Document/DocumentTemplateEditor.vue'
                    ),
              },
              {
                path: 'fordelning',
                name: 'estateDistributionSplit',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-distribution" */
                    '@/views/EstateDistribution/Split.vue'
                    ),
              },
              {
                path: 'lottlaggning',
                name: 'propertyDivision',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-distribution" */
                    '@/views/EstateDistribution/Division.vue'
                    ),
              },
              {
                path: 'handlingar',
                name: 'estateDistributionDocuments',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-distribution" */
                    '@/views/EstateDistribution/DocumentRows.vue'
                    ),
              },
              {
                path: 'att-satser',
                name: 'estateDistributionStatements',
                component: () =>
                  import(
                    /* webpackChunkName: "estate-distribution" */
                    '@/views/EstateDistribution/Statements.vue'
                    ),
              },
            ],
          }
        ],
      },
    ],
  },
];

function flattenRoutes(routes: RouteConfig[]): RouteConfig[] {
  return routes.reduce((acc, current) => {
    acc.push(current);
    if (current.children) {
      acc.push(...flattenRoutes(current.children));
    }
    return acc;
  }, [] as RouteConfig[]);
}

const flatRoutes = flattenRoutes(routes as RouteConfig[]);

export function getRoute(name: string): RouteConfig | undefined {
  // NOTE: This might cause issus if reading path straight up
  const found = flatRoutes.filter(r => r.name == name);
  if (found.length) {
    return found[0];
  }
}

const router = new VueRouter({
  mode: 'history',
  routes: routes as RouteConfig[],
});

function getCurrentId(path: string): number | null {
  return get(store, `state.${path}.current.id`) || null;
}

export function handleRouteParams(params: Record<string, string>): void {
  const parsedParams = mapValues(params, (val: string) => {
    try {
      return parseInt(val);
    } catch {
      return val;
    }
  });

  const {
    caseId,
    estateInventoryId,
    estateDivisionId,
    estateDistributionId,
    partyId,
    ownershipId,
    transactionId,
  } = parsedParams;

  // If case id in route change, update
  if (caseId != getCurrentId('case')) {
    store.commit('case/current/SET_ID', caseId);
    if (caseId) {
      // if not null and changed, fetch entity
      store.dispatch('case/list/get', caseId);
    } else {
      // TODO: Mave move this inside case module?
      store.commit('case/reset');
    }

    // document was set, so need to reset it when case is changed

    if (getCurrentId('estateInventory')) {
      store.commit('estateInventory/reset');
    }
    if (getCurrentId('estateDivision')) {
      store.commit('estateDivision/reset');
    }
    if (getCurrentId('estateDistribution')) {
      store.commit('estateDistribution/reset');
    }
  }

  // estateInventoryId param set and changed
  if (
    estateInventoryId &&
    estateInventoryId !== getCurrentId('estateInventory')
  ) {
    store.commit('estateInventory/reset');
    store.commit('estateInventory/current/SET_ID', estateInventoryId);
  }

  if (
    estateDivisionId &&
    estateDivisionId !== getCurrentId('estateDivision')
  ) {

    store.commit('estateDivision/reset');
    store.commit('estateDivision/current/SET_ID', estateDivisionId);
  }

  if (
    estateDistributionId &&
    estateDistributionId !== getCurrentId('estateDistribution')
  ) {

    store.commit('estateDistribution/reset');
    store.commit('estateDistribution/current/SET_ID', estateDistributionId);
  }

  if (partyId != getCurrentId('estateInventory.party')) {
    store.commit('estateInventory/party/current/SET_ID', partyId);
  }

  if (ownershipId != getCurrentId('estateInventory.ownership')) {
    store.commit('estateInventory/ownership/current/SET_ID', ownershipId);
  }

  if (transactionId != getCurrentId('transaction')) {
    store.commit('transaction/current/SET_ID', transactionId);
  }
}

router.beforeEach(async (to: Route, from: Route, next: Function) => {
  if (!store.state.initialized) {
    await store.dispatch('initialize');
  }
  next();
});

router.beforeEach(async (to: Route, from: Route, next: Function) => {
  const {isAuthenticated} = store.state.auth;
  // const { auth } = to.meta;
  const auth = to.matched.find(r => 'auth' in r.meta)?.meta?.auth;

  if (auth === true && !isAuthenticated) {
    store.commit('auth/SET_REDIRECT_TO', to.fullPath);
    next({name: 'login'});
  } else if (auth === false && isAuthenticated) {
    // Maybe just stop navigation ehere, and show a snackbar?
    next({name: 'home'});
  } else {
    next();
  }
});

router.beforeResolve(async (to: Route, from: Route, next: Function) => {
  // NOTE: Here, we should check meta (like auth), and if an entity is required,
  // and the id is not found it should redirect to not found?
  handleRouteParams(to.params);
  next();
});

export default router;
