import { TestContext, ValidationError } from 'yup';
import i18n from '@/plugins/i18n';
import validator from 'validator';
import Vue from 'vue';
import matches = validator.matches;
import isEmpty = validator.isEmpty;
import isEmail = validator.isEmail;

// Derived from https://jymden.com/validate-swedish-personnummer-and-organisationsnummer/
export function validatePidYear(pid: string) {
    const century = pid.substr(0, 2);
    switch (century) {
        case '18':
        case '19':
        case '20':
            // Valid centuries
            // NOTE: org number could start with 16, but should not hit this func?
            return true;
        default:
            return false;
    }
}

export function validatePidDate(pid: string) {
    const year = parseInt(pid.substring(0, 2));
    const month = parseInt(pid.substring(2, 4));
    const day = parseInt(pid.substring(4, 6));

    // Check year and month values
    if (year < 0 || year > 99 || month < 0 || month > 12) {
        return false;
    }

    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
        daysInMonth[1] = 29;
    }

    // Check that day is within range
    let dayIsValid = day > 0 && day <= daysInMonth[month - 1];

    // If day is outside range, check if it's +60 for samordningsnummer
    if (!dayIsValid) {
        dayIsValid = day > 60 && day <= daysInMonth[month - 1] + 60;
    }

    return dayIsValid;
}

export function validatePidYearAndDate(pid: string): boolean {
    return validatePidYear(pid) && validatePidDate(pid.substring(2));
}

export function validateLuhn(pid: string): boolean {
    let number;
    let checksum = 0;
    for (let i = pid.length - 1; i >= 0; i--) {
        number = parseInt(pid.charAt(i));
        if (i % 2 == 1) {
            checksum += number;
        } else {
            checksum += number * 2 > 9 ? number * 2 - 9 : number * 2;
        }
    }

    return checksum % 10 == 0;
}

// Function checking if value is an organisation number
export function isOrgNumber(number: string | null): boolean {
    if (!number) {
      return false;
    }
    // We want the month digits to check if they are greater or equal than 20
    const numberString = number.replace(/\D/g, '');
    let monthString: string;
    switch (numberString.length) {
        case 10:
          monthString = numberString.substr(2, 2);
          break;
        case 12:
            monthString = numberString.substr(4, 2);
            break;
        default:
            return false;
    }
    const parsed = parseInt(monthString);
    return 20 <= parsed;
}

export function isPidNumber(number: string): boolean {
    return /^\d{4}([0]\d|1[0-2])([0-2]\d|3[01])(-?\d{4})?$/.test(number);
}

export function validatePid(
    context: TestContext,
    number: string
): boolean | ValidationError {
    let pid = number.replace(/\D/g, '');

    if (pid.length == 8 || pid.length == 12) {
        if (!validatePidYear(pid)) {
            const century = pid.substr(0, 2);
            return context.createError({
                message: i18n.t('form.validation.pid.date').toString(),
                params: {number, century},
            });
        }
        // If we have centry, remove it so date validation is proper
        pid = pid.substring(2);
    }

    if (!validatePidDate(pid)) {
        return context.createError({
            message: i18n.t('form.validation.pid.date').toString(),
            params: {number},
        });
    }

    if (pid.length < 10) {
        return true;
    }

    // We allow incomplete pids, missing 4 trailing, so don't validate
    // with lhn in that case
    if (pid.length == 10 && !validateLuhn(pid)) {
        return context.createError({
            message: i18n.t('form.validation.pid.luhn').toString(),
            params: {number},
        });
    }

    return true;
}

function validateOrgNumber(
    context: TestContext,
    number: string
): boolean | ValidationError {
    const orgnumber = number.replace(/\D/g, '');

    // NOTE: Do we need any additional checks here, like length? Or is prev
    // regex test enough?
    if (!validateLuhn(orgnumber)) {
        return context.createError({
            message: 'luhn',
            params: {number},
        });
    }

    return true;
}

// Validate id number, either pid or orgNumber
export function validateIdNumber(
    this: TestContext,
    number: string
): boolean | ValidationError {
    if (isOrgNumber(number)) {
        return validateOrgNumber(this, number);
    }
    return validatePid(this, number);
}

// -- Taken from MinGava frontend --
export const isString = (v: any) => typeof v == 'string';

export const ssnPattern = /^(19|20)[0-9]{6}[-\s]?[0-9]{4}$/;
export const mobilePattern = /[1-9]\d{7,14}$/;
export const onlyNumericPlus = /[^0-9+]/;
export const onlyNumeric = /[0-9]/;

const VALID_PHONE_START = [
    '070',
    '+70',
    '70',
    '+4670',
    '072',
    '72',
    '+72',
    '+4672',
    '073',
    '73',
    '+4673',
    '+73',
    '076',
    '+76',
    '76',
    '+4676',
    '079',
    '+79',
    '79',
    '+4679',
];

export function hasValidPhoneStartPrefix(phoneNumber: string): boolean {
    for (const prefix of VALID_PHONE_START) {
        if (phoneNumber.startsWith(prefix)) {
            return true;
        }
    }

    return false;
}

export function hasValidMobilePhoneNumber(phoneNumber: string): boolean {
    return (
        matches(phoneNumber, mobilePattern) &&
        !matches(phoneNumber, onlyNumericPlus) &&
        hasValidPhoneStartPrefix(phoneNumber)
    );
}

export default function validators(messages: any) {
    return {
        required: [(v: any) => !!v || messages.mixed.required],
        validPercentage: [(v: any) => Number.parseFloat(v) >= 0 || messages.number.positive, (v: any) => Number.parseFloat(v) <= 100 ||  messages.number.notMoreThanFullPercentage ],
        greaterThanZero: [(v: any) => Number.parseFloat(v) > 0 || messages.number.moreThanZero],
        // eslint-disable-next-line @typescript-eslint/camelcase
        isNotEmpty: [
            (v: any) =>
                // eslint-disable-next-line @typescript-eslint/camelcase
                (isString(v) && !isEmpty(v, {ignore_whitespace: true})) ||
                messages.mixed.required,
        ],
        email: [(v: any) => !v || isEmail(v) || messages.string.email],
        // NOTE: This is a swedish social security number check, need to adjust if used in other localizations
        pid: [
            (v: any) => !isString(v) || matches(v, ssnPattern) || messages.pid.format,
        ],
        mobilePhoneNumber: [
            (v: any) => !v || hasValidMobilePhoneNumber(v) || messages.string.phone,
        ],
        onlyNumeric: [(v: any)  => onlyNumeric.test(v) || messages.number.numbersAndDecimal],
        date: [
            (v: any) =>
                !v ||
                matches(v, /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/) ||
                messages.date.format,
        ],
    };
}

export function hasNumericalKeyCode(code: string) {
  return code.includes('Digit');
}

export function hasPunctuationKeyCode(code: string) {
  return code === 'Comma' || code === 'Period';
}

export function hasNumericalOrDecimalKey(code: string) {
  return hasNumericalKeyCode(code) || hasPunctuationKeyCode(code);
}

export function isFormValid(vue: Vue, form: any): boolean {
    if (!form.validate()) {
        for (let i = 0; i < form.$children.length; i++) {
            const child = form.$children[i];
            if (!child.valid) {
                vue.$vuetify.goTo(child, {offset: 50});
                return false;
            }
        }
    }
    return true;
}

export function validateDate(date: string) {
  const dateReg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
  return date.match(dateReg) != null;
}
