import {
  OwnershipConfig,
  PartyConfig,
  PersonConfig,
} from '@/models';
import { createConfigApi } from './';

export const party = createConfigApi<PartyConfig>('/api/config/party');
export const ownership = createConfigApi<OwnershipConfig>(
  '/api/config/ownership'
);
export const person = createConfigApi<PersonConfig>('/api/config/person');
