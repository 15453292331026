import Vue from 'vue';
import Vuex from 'vuex';

import { VuexReset } from '@/vuex/utils';
import auth, { AuthState } from '@/vuex/auth';
import health, { HealthState } from '@/vuex/health';

import caseModule, { CaseState } from '@/vuex/case';
import deceased, { DeceasedState } from '@/vuex/deceased';
import estateInventory, { EstateInventoryState } from '@/vuex/estate-inventory';
import transaction, { TransactionState } from '@/vuex/transaction';
import config, { ConfigState } from '@/vuex/config';
import document, { DocumentState } from '@/vuex/document';
import estateDivision, { EstateDivisionState} from "@/vuex/estate-division";
import estateDistribution, {EstateDistributionState} from "@/vuex/estate-distribution";

Vue.use(Vuex);

export interface RootState {
  initialized: boolean;
  config: ConfigState;
  auth: AuthState;
  health: HealthState;
  case: CaseState;
  deceased: DeceasedState;
  estateInventory: EstateInventoryState;
  estateDivision: EstateDivisionState;
  estateDistribution: EstateDistributionState;
  transaction: TransactionState;
  document: DocumentState;
}

export default new Vuex.Store<RootState>({
  state: () => { return {
    initialized: false,
  } as RootState},
  // TODO: Disable logging in prod?
  plugins: [VuexReset()],
  modules: {
    auth,
    health,
    deceased,
    case: caseModule,
    estateInventory,
    estateDivision,
    estateDistribution,
    transaction,
    config,
    document,
  },
  mutations: {
    SET_INITIALIZED(state: RootState) {
      state.initialized = true;
    },
  },
  actions: {
    async initialize({ commit, state, dispatch }) {
      if (state.initialized) {
        throw new Error('already initialized');
      }
      dispatch('health/getHealth');
      dispatch('document/getFormats');
      await dispatch('auth/getStatus');
      commit('SET_INITIALIZED');
    },
  },
});
