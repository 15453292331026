import Vue from 'vue';
import get from 'lodash/get';
import {
  Ownership,
  OwnershipAdjustment,
  OwnershipCategory,
  OwnershipOwner,
  OwnershipType
} from '@/models';
import {
  ASSET_CATEGORIES,
  LIABILITY_CATEGORIES,
  OWNERSHIP_COUNT_CATEGORIES,
  OWNERSHIP_INTEREST_CATEGORIES,
  OWNERSHIP_RATE_CATEGORIES,
  OWNERSHIP_SHARE_CATEGORIES
} from '@/constants';
import { OwnershipSummary } from '@/models/ownership/ownershipSummary';
import {
  OwnershipCalculationMethod
} from '@/enums/ownership-calculation-method';

export function isShareCategory(category: OwnershipCategory): boolean {
  return OWNERSHIP_SHARE_CATEGORIES.includes(category as any);
}

export function isCountCategory(category: OwnershipCategory): boolean {
  return OWNERSHIP_COUNT_CATEGORIES.includes(category as any);
}

export function isRateCategory(category: OwnershipCategory): boolean {
  return OWNERSHIP_RATE_CATEGORIES.includes(category as any);
}

export function isInterestCategory(category: OwnershipCategory): boolean {
  return OWNERSHIP_INTEREST_CATEGORIES.includes(category as any);
}

export function isLiabilityCategory(category: OwnershipCategory): boolean {
  return LIABILITY_CATEGORIES.includes(category as any);
}

export function isAssetCategory(category: OwnershipCategory): boolean {
  return ASSET_CATEGORIES.includes(category as any);
}

export function getOwnershipValue(entity: Ownership, currency: string): number {
  // a sum has been manually entered, so we ignore everything else such as interest and quantity
  if (
    entity.calculationMethod &&
    entity.calculationMethod === OwnershipCalculationMethod.NO_CALCULATION
  ) {
    return entity.manualTotalSum ? entity.manualTotalSum : 0;
  }

  let value = entity.value;

  if (!value) {
    return 0;
  }


  if (currency !== entity.currency) {
    if (!entity.conversionRate) {
      return 0;
    }
    value *= entity.conversionRate!;
  }

  if (entity.count) {
    value *= entity.count;
  }

  let interest = entity.interest;
  if (entity.share) {
    value *= entity.share;
    if (interest) {
      interest *= entity.share;
    }
  }

  if (interest) {
    value += interest;
  }

  return value;
}

export function buildSum(ownershipType: OwnershipType, text: string) {
  return {
    ownershipType: ownershipType,
    assetSum: 0,
    liabilitySum: 0,
    text: text
  } as OwnershipSummary;
}

export function mapToOwnershipSummary(entity: Ownership[], rootState: any) {
  return entity.map(e =>
    (
      {
        ownershipType: e.type,
        assetSum: e.assetType == "ASSET" ? getOwnershipValue(
          e,
          rootState.config.ownership.data?.defaultCurrency
        ) : null,
        liabilitySum: e.assetType == "LIABILITY" ? getOwnershipValue(
          e,
          rootState.config.ownership.data?.defaultCurrency
        ) : null
      }) as OwnershipSummary
  )
}

export function addAssetForValue(value: OwnershipSummary, sumMarital: OwnershipSummary, sumJoint: OwnershipSummary, sumPersonal: OwnershipSummary, sumPrivate: OwnershipSummary) {
  switch (value.ownershipType) {
    case "JOINT":
      value.assetSum ? sumJoint.assetSum += value.assetSum : sumJoint.liabilitySum += value.liabilitySum;
      break;
    case "PERSONAL":
      value.assetSum ? sumPersonal.assetSum += value.assetSum : sumPersonal.liabilitySum += value.liabilitySum;
      break;
    case "PRIVATE":
      value.assetSum ? sumPrivate.assetSum += value.assetSum : sumPrivate.liabilitySum += value.liabilitySum;
      break;
    case "MARITAL":
      value.assetSum ? sumMarital.assetSum += value.assetSum : sumMarital.liabilitySum += value.liabilitySum;
      break;
  }
  return null;
}


export function getOwnershipSummary(entities: Record<OwnershipOwner,
  Record<OwnershipCategory, Ownership[]>>, owner: OwnershipOwner, rootState: any,
): Array<OwnershipSummary> | null {

  const sumMarital = buildSum("MARITAL", 'generic.ownership.ownershipTypes.MARITAL');
  const sumJoint = buildSum("JOINT", 'generic.ownership.ownershipTypes.JOINT');
  const sumPersonal = buildSum("PERSONAL", 'generic.ownership.ownershipTypes.PERSONAL');
  const sumPrivate = buildSum("PRIVATE", 'generic.ownership.ownershipTypes.PRIVATE');

  for (const c in entities[owner as OwnershipOwner]) {
    const cat = c as OwnershipCategory;
    const value = mapToOwnershipSummary(entities[owner][cat] as Ownership[], rootState);

    value.forEach(value => {
      addAssetForValue(value, sumMarital, sumJoint, sumPersonal, sumPrivate);
    });
  }
  const items = Array<OwnershipSummary>();
  items.push(sumMarital);
  items.push(sumPersonal);
  items.push(sumJoint);
  items.push(sumPrivate);
  return items;
}


const OWNERSHIP_MISSING_PREFIX = 'generic.ownership.missing' as const;

export function getOwnershipMissing(
  entity: Ownership,
  options: { currency?: string } = {}
): string[] {
  const {currency} = options;
  const errors = [] as string[];
  if (entity.value === null) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.value`);
  }

  if (isShareCategory(entity.assetCategory) && entity.share === null) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.share`);
  }

  if (isCountCategory(entity.assetCategory) && entity.count === null) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.count`);
  }

  if (
    entity.owner === 'DECEASED' &&
    isAssetCategory(entity.assetCategory) &&
    entity.type === null
  ) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.type`);
  }

  if (currency !== entity.currency && entity.conversionRate === null) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.conversionRate`);
  }

  return errors;
}

export function getOwnershipAdjustmentMissing(
  entity: OwnershipAdjustment,
  options: { currency?: string } = {}
): string[] {
  const {currency} = options;
  const errors = [] as string[];
  if (entity.value === null) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.value`);
  }
  if (
    entity.owner === null &&
    entity.type === null
  ) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.type`);
  }

  if (currency !== entity.currency && entity.conversionRate === null) {
    errors.push(`${OWNERSHIP_MISSING_PREFIX}.conversionRate`);
  }

  return errors;
}


export function conversionRateText(currencyKey: string) {
  return function (
    this: Vue,
    values: {
      originalAmount: number;
      currency: string;
      conversionRate: number;
    }
  ): string {
    const {currency, conversionRate, originalAmount} = values;
    return `${this.$n(originalAmount, 'currency')} ${currency} @ ${this.$n(
      conversionRate,
      'conversionRate'
    )} ${get(this, currencyKey)}`;
  };
}
