import {Module} from 'vuex';

import {
  EstateDivisionParams,
  Ownership,
  OwnershipAdjustment,
  OwnershipCategory,
  OwnershipOwner,
} from '@/models';
import {
  ASSET_CATEGORIES,
  ASSET_TYPE_CATEGORIES,
  ESTATE_DIVISION_API_PARAMS_GETTER,
  LIABILITY_CATEGORIES,
  OWNERSHIP_OWNERS,
} from '@/constants';
import api from '@/api/ownership-adjustment';
import {getOwnershipSummary, getOwnershipValue} from '@/utils/ownership';

import {
  createCurrentEntityModule,
  createEntityListModule,
  CurrentEntityState,
  EntityListState,
} from '../utils';

export interface OwnershipAdjustmentState {
  list: EntityListState<OwnershipAdjustment>;
  current: CurrentEntityState<OwnershipAdjustment>;
}

const module: Module<OwnershipAdjustmentState, any> = {
  namespaced: true,
  modules: {
    list: createEntityListModule<OwnershipAdjustment, EstateDivisionParams>(api, {
      apiParamsGetter: ESTATE_DIVISION_API_PARAMS_GETTER,
    }),
    current: createCurrentEntityModule<OwnershipAdjustment>({
      listStatePath: 'estateDivision.ownershipAdjustment.list',
    }),
  },
  getters: {
    apiParams(state: any, rootState: any, rootGetters: any) {
      return {
        ...rootGetters[ESTATE_DIVISION_API_PARAMS_GETTER],
      };
    },
    byOwner(state: any): Record<OwnershipOwner, OwnershipAdjustment[]> {
      const result = {} as Record<OwnershipOwner, OwnershipAdjustment[]>;
      for (const owner of OWNERSHIP_OWNERS) {
        result[owner] = state.list.entities.filter(
          (e: Ownership) => e.owner === owner
        );
      }

      return result;
    }
  },
};

export default module;
