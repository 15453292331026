<template>
  <v-avatar :size="hasImageLink ? (size * 1.1) : size" :class="backgroundColor">
    <img style="object-fit: cover;" v-if="hasImageLink" :src="imgSrc">
    <span v-else class="white--text">{{initials}}</span>
  </v-avatar>
</template>

<script>
import Vue from "vue";

export default Vue.extend( {
  data()
  {
    return {
      colorClasses: [
        "deep-purple lighten-3",
        "pink lighten-2",
        "purple lighten-2",
        "indigo lighten-2",
        "blue lighten-2",
        "light-blue lighten-1",
        "cyan lighten-2",
        "teal lighten-2",
        "green lighten-2",
        "light-green lighten-2",
        "lime lighten-2",
        "orange lighten-2",
        "deep-orange lighten-3",
        "red lighten-3",
        "brown lighten-2",
        "blue-grey lighten-1"
      ]
    }
  },
  computed: {
    initials()
    {
      if (this.firstname && this.lastname)
      {
        return this.firstname.charAt(0) + this.lastname.charAt(0)
      }
      return "";
    },
    backgroundColor()
    {
      if (this.color)
      {
        return this.color
      }
      return this.getColorFromName(this.firstname + this.lastname)
    },
    hasImageLink()
    {
      return this.imgSrc && this.imgSrc.length > 0;
    }
  },
  methods: {
    // makes sure a user always has the same color (determined by length of the username)
    getColorFromName(name)
    {
      let colorIndex = 0;

      for (let i = 0; i < name.length; i++)
      { // iterate characters in the username
        colorIndex++;
        if (colorIndex >= this.colorClasses.length)
        {
          colorIndex = 0
        }
      }
      return this.colorClasses[colorIndex]
    },
  },
  name: 'InitialsAvatarComponent',
  props: ["imgSrc", "color", "size", "firstname", "lastname"]
});
</script>
