















































































































































import Vue from 'vue';
import {mapActions, mapState} from 'vuex';
import {getRoute} from '@/plugins/router';

import {CaseDocumentType, CaseItem} from '@/models';
import {getCaseText} from '@/utils';
import {LATEST_CASES_KEY, CASE_DOCUMENT_TYPE_TRANSLATIONS} from '@/constants';
import InitialsAvatarComponent from '@/components/InitialsAvatarComponent.vue';
import CreateCaseDocumentForm from '@/components/case/CreateCaseDocumentForm.vue';
import store from "@/plugins/store";

export default Vue.extend({
  name: 'Navigation',
  components: {
    CreateCaseDocumentForm,
    InitialsAvatarComponent,
  },
  data() {
    return {
      CASE_DOCUMENT_TYPE_TRANSLATIONS,
      // create
      showCreateForm: false,
      createFormVisible: false,
      selectedCaseId: null as number | null,
      // latest
      latestIds: [] as number[],
    };
  },
  created() {
    this.loadLatestIds();
  },
  computed: {
    ...mapState('case/current', {caseId: 'id'}),
    ...mapState('case/list', {caseItems: 'entities'}),
    ...mapState('estateInventory/current', {estateInventoryId: 'id'}),
    ...mapState('estateDivision/current', {estateDivisionId: 'id'}),
    ...mapState('estateDistribution/current', {estateDistributionId: 'id'}),
    ...mapState('auth', {userAccount: 'data'}),
    caseRoute(): string | undefined {
      const route = getRoute('case');
      return route?.path;
    },
    recentCaseItems(): (CaseItem | undefined)[] {
      const items = this.latestIds.map(id =>
          (this.caseItems as CaseItem[]).find(item => item.id == id)
      );
      return items;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    getCaseText,
    getCaseRoute(id: number): string | undefined {
      return this.caseRoute?.replace(':caseId', id.toString());
    },
    caseItemHasEstateInventories(item: CaseItem | null): boolean {
      return !!item?.estateInventories?.length;
    },
    caseItemHasEstateDivisions(item: CaseItem | null): boolean {
      return !!item?.estateDivisions?.length;
    },
    caseItemHasEstateDistributions(item: CaseItem | null): boolean {
      return !!item?.estateDistributions?.length;
    },
    async onLogout() {
      if (await this.logout()) {
        this.$router.push({name: 'login'});
      } else {
        // eslint-disable-next-line no-console
        console.warn('failed to logout');
      }
    },
    getLatestIdsFromLocalStorage(): number[] | undefined {
      const data = localStorage.getItem(LATEST_CASES_KEY);
      if (data) {
        return JSON.parse(data);
      }
    },
    loadLatestIds() {
      const id = this.getLatestIdsFromLocalStorage();
      if (id) {
        this.latestIds = id;
        this.$store.dispatch('case/list/load', {id});
      }
    },
    saveLatestIds(data: CaseItem['id'][]) {
      localStorage.setItem(LATEST_CASES_KEY, JSON.stringify(data));
    },
    createDocument(id: CaseItem['id']) {
      this.selectedCaseId = id;
      this.showCreateForm = true;
    },
    onCreate(entity: any, type?: CaseDocumentType) {
      // We'll retrieve the whole case object, so it'll show up in the menu

      let estateInventory = {}

      switch (type) {
        case 'ESTATE_INVENTORY':
          estateInventory = {
            name: 'estateInventory',
            params: {
              estateInventoryId: entity.id.toString(),
              caseId: this.caseId.toString(),
            }
          }
          store.commit('document/updateActiveDocumentType', type);
          break;
        case 'ESTATE_DIVISION':
          estateInventory = {
            name: 'estateDivision',
            params: {
              estateDivisionId: entity.id.toString(),
              caseId: this.caseId.toString(),
            }
          }
          store.commit('document/updateActiveDocumentType', type);
          break;
        case 'ESTATE_DISTRIBUTION':
          estateInventory = {
            name: 'estateDistribution',
            params: {
              estateDistributionId: entity.id.toString(),
              caseId: this.caseId.toString(),
            }
          }
          store.commit('document/updateActiveDocumentType', type);
          break;
        default:
          estateInventory = {
            name: 'estateInventory',
            params: {
              estateInventoryId: entity.id.toString(),
              caseId: this.caseId.toString(),
            }
          }
          store.commit('document/updateActiveDocumentType', 'ESTATE_INVENTORY');
          break;
      }

      this.$store.dispatch('case/list/get', this.selectedCaseId);
      this.showCreateForm = false;
      this.$router.push(estateInventory);
    },
    onCaseClick(item: CaseItem) {
      // half hacky way to allow navigating between cases without menu closing
      // when on mobile.
      // NOTE: This should probably be extract to different components for
      // layout mobile/desktop?
      if (this.$vuetify.breakpoint.mobile) {
        this.selectedCaseId = item.id;
      } else {
        this.$router.push({name: 'case', params: {caseId: item.id} as any});
      }
    },
    isOpened(item: CaseItem): boolean | undefined {
      if (this.$vuetify.breakpoint.mobile) {
        return item.id == this.selectedCaseId;
      } else {
        return undefined;
      }
    },
  },
  watch: {
    caseId(val: CaseItem['id'] | null) {
      if (val) {
        this.latestIds = [val, ...this.latestIds.filter(id => id != val)];
        if (5 < this.latestIds.length) {
          this.latestIds = this.latestIds.slice(0, 5);
        }
      }
    },
    latestIds(val: CaseItem['id'][]) {
      this.saveLatestIds(val);
    },
    showCreateForm(val) {
      if (val) {
        this.createFormVisible = true;
      } else if (this.createFormVisible) {
        setTimeout(() => {
          this.createFormVisible = false;
          this.selectedCaseId = null;
        }, 300);
      }
    },
    createFormVisible(val) {
      if (!val && this.showCreateForm) {
        setTimeout(() => {
          this.showCreateForm = false;
          this.selectedCaseId = null;
        }, 300);
      }
    },
  },
});
