import { Module } from 'vuex';

export interface SavingState {
  isSaving: boolean;
  saveError: Error | null;
}

export function createSavingModule(): Module<SavingState, any> {
  return {
    state: {
      isSaving: false,
      saveError: null,
    },
    mutations: {
      SET_SAVING(state: SavingState, loading: boolean) {
        state.isSaving = loading;
      },
      SET_SAVE_ERROR(state: SavingState, error: Error | null) {
        state.saveError = error;
        // TODO: Set error message here?
      },
    },
  };
}
