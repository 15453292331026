






















import Vue from 'vue';

export default Vue.extend({
  name: 'ErrorAlert',
  props: {
    error: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showRetry: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
});
