import { Module } from 'vuex';

import { ESTATE_INVENTORY_API_PARAMS_GETTER } from '@/constants';
import { DocumentRows } from '@/models';
import api from '@/api/estate-inventory/document-rows';

import { createSingleEntityModule, SingleEntityState } from '../utils';

export interface DocumentRowsState {
  entity: SingleEntityState<DocumentRows>;
}

const module: Module<DocumentRowsState, any> = {
  namespaced: true,
  modules: {
    entity: createSingleEntityModule<DocumentRows>(
      {
        apiParamsGetter: ESTATE_INVENTORY_API_PARAMS_GETTER,
      },
      api
    ),
  },
};

export default module;
