






import Vue from 'vue';
import { mapState } from 'vuex';

import LoginForm from '@/components/auth/LoginForm.vue';

export default Vue.extend({
  components: {
    LoginForm,
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = !this.isAuthenticated;
  },
  computed: {
    ...mapState('auth', ['isLoading', 'loadError', 'data', 'isAuthenticated']),
  },
  methods: {},
  watch: {
    isAuthenticated(val: boolean) {
      this.show = !val;
    },
  },
});
