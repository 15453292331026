






























import Vue from 'vue';
import {mapState} from 'vuex';

export default Vue.extend({
  components: {},
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState('case/current', {caseId: 'id'}),
    ...mapState('estateDistribution/current', {estateDistributionId: 'id'}),
    params() {
      const {caseId, estateDistributionId} = this;
      return {caseId, estateDistributionId};
    },
  },
});
