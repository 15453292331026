import {Module} from 'vuex';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';

import {
    EstateInventoryParams,
    Party,
    PartyRelation,
    PartySummonData,
} from '@/models';
import {ESTATE_INVENTORY_API_PARAMS_GETTER} from '@/constants';
import api from '@/api/estate-inventory/party';

import {
    createCurrentEntityModule,
    createEntityListModule,
    createSavingModule,
    CurrentEntityState,
    EntityListState,
    SavingState,
} from '../utils';

const saveModule = createSavingModule();

export type PartyState = SavingState & {
    list: EntityListState<Party>;
    current: CurrentEntityState<Party>;
};

const module: Module<PartyState, any> = {
    namespaced: true,
    state: {
        ...(saveModule.state as SavingState),
    } as PartyState,
    modules: {
        list: createEntityListModule<Party, EstateInventoryParams>(api, {
            apiParamsGetter: ESTATE_INVENTORY_API_PARAMS_GETTER,
        }),
        current: createCurrentEntityModule<Party>({
            listStatePath: 'estateInventory.party.list',
        }),
    },
    mutations: {
        ...saveModule.mutations,
    },
    getters: {
        apiParams(state: any, rootState: any, rootGetters: any) {
            return {
                ...rootGetters[ESTATE_INVENTORY_API_PARAMS_GETTER],
                partyId: state.current.id,
            };
        },
        relations(state: any) {
            let relations = state.list.entities.map((e: Party) => e.relations);
            relations = flatten(relations);
            return uniqBy(relations, (r: PartyRelation) => r.id);
        },
        hasSpouse(state: any, getters: any) {
            return getters['relations'].some(
                (r: PartyRelation) =>
                    r.targetEntityType === 'CASE_ITEM_PERSON' && r.type === 'SPOUSE'
            );
        },
        hasPartner(state: any, getters: any) {
            return getters['relations'].some(
                (r: PartyRelation) =>
                    r.targetEntityType === 'CASE_ITEM_PERSON' && r.type === 'PARTNER'
            );
        },
        getSpouse(state: any, getters: any): Party | null {
          const spouseRelations: Array<PartyRelation> = getters['relations'].filter(
            (r: PartyRelation) =>
              r.targetEntityType === 'CASE_ITEM_PERSON' && r.type === 'SPOUSE'
          );

          if (spouseRelations.length > 0) {
            const entity: PartyRelation = spouseRelations[0];
            const partyId = entity.sourceEntityId;

            return state.list.entities.find((p: Party) => p.id === partyId);
          }

          return null;
        },
    },
    actions: {
        async updateSummons({commit, rootGetters}, req: PartySummonData[]) {
            commit('SET_SAVING', true);
            commit('SET_SAVE_ERROR', null);
            try {
                const data = await api.summons(
                    rootGetters[ESTATE_INVENTORY_API_PARAMS_GETTER],
                    req
                );
                data.map((e: Party) => commit('list/ADD_ENTITY', e));
                return data;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                commit('SET_SAVE_ERROR', error);
            } finally {
                commit('SET_SAVING', false);
            }
            return false;
        },
    },
};

export default module;
