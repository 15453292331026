






















































import Vue from 'vue';

import { CaseItem } from '@/models';
import { CASE_TYPE_TRANSLATIONS } from '@/constants';
import { createEntitySnackbar } from '@/utils/snackbar';

export default Vue.extend({
  name: 'CaseTable',
  props: {
    data: {
      type: Array as () => CaseItem[],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {
          value: 'name',
          text: this.$t('view.case.table.headers.deceased'),
        },
        {
          value: 'personalIdNumber',
          text: this.$t('view.case.table.headers.personalIdNumber'),
          class: 'collapsed',
        },
        {
          value: 'caseType',
          text: this.$t('view.case.table.headers.caseType'),
          class: 'collapsed',
        },
        {
          value: 'createdAt',
          text: this.$t('view.case.table.headers.createdAt'),
          class: 'collapsed',
        },
        {
          value: 'updatedAt',
          text: this.$t('view.case.table.headers.updatedAt'),
          class: 'collapsed',
        },
        {
          text: '',
          value: 'remove',
          class: 'collapsed',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    items(): any {
      return this.data.map((item: CaseItem) => {
        return {
          //...pick(item, 'id', 'createdAt', 'updatedAt'),
          ...item,
          name: `${item.person.firstName || ''} ${item.person.lastName || ''}`,
          caseType: this.$t(CASE_TYPE_TRANSLATIONS[item.caseType]),
        };
      });
    },
    tableHeight() {
      const heightThreshold = 500;
      if (this.items.length <= 10) {
        return undefined;
      } else {
        return heightThreshold;
      }
    },
  },
  methods: {
    select(data: any) {
      this.$router.push({ name: 'deceased', params: { caseId: data.id } });
    },
    async remove(item: CaseItem) {
      if (
        !(await this.$confirm({
          text: 'dialog.confirm.entities.caseItem.delete',
          color: 'error',
        }))
      ) {
        return;
      }
      const success = !!(await this.$store.dispatch(
        'case/list/delete',
        item.id
      ));

      this.$snackbar(createEntitySnackbar('delete', 'caseItem', { success }));
    },
    filter(value: any, search: string, item: CaseItem) {
      const { person } = item;
      const { address } = person || {};
      const s = search.toLowerCase();
      const v = !value ? null : ('' + value).toLowerCase();

      const contains = (o: Record<string, any>) =>
        !!o &&
        Object.values(o)
          .filter((v: any) => !!v && typeof v !== 'object')
          .some((v: any) => ('' + v).includes(search));

      return !!v?.includes(s) || contains(person) || contains(address);
    },
  },
});
