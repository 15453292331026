import Vue from 'vue';
import VueI18n, {LocaleMessages, TranslateResult} from 'vue-i18n';
import {formatRelative} from 'date-fns';
import {sv} from 'date-fns/locale';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context(
        './../locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages: LocaleMessages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const numberFormats = {
    sv: {
        currency: {
            // We probably don't want to show this as currency can be selected
            // style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        short: {
            maximumFractionDigits: 0,
        },
        conversionRate: {
            // We probably don't want to show this as currency can be selected
            // style: 'currency',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
        },
        decimal: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 5,
        },
        fundDecimal: {
            minimumFractionDigits: 5,
            maximumFractionDigits: 5,
        },
    },
};

const dateTimeFormats = {
    sv: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    },
};

// This extension is used in forms, so you just need to add a hint key in the
// translations and it will show up as a hint
Vue.prototype.$to = function (key: string): TranslateResult | undefined {
    return this.$te(key) ? this.$t(key) : undefined;
};

// Relative date
Vue.prototype.$rd = function (value: Date | string): string | undefined {
    if (!value) {
        return undefined;
    }
    if (typeof value === 'string') {
        value = new Date(value);
    }

    // TODO: How to handle locale properly?
    return formatRelative(value, new Date(), {locale: sv});
};

declare module 'vue/types/vue' {
    interface Vue {
        // Extended optional i18n function
        $to: typeof VueI18n.prototype.t;
        $rd: typeof VueI18n.prototype.t;
    }
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'sv',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'sv',
    messages: loadLocaleMessages(),
    numberFormats: numberFormats,
    dateTimeFormats,
});
