import {Module} from 'vuex';

import {CaseItemParams} from '@/models';
import {CASE_API_PARAMS_GETTER} from '@/constants';

import {getEstateDistributionDocumentUrl} from '@/api/document';

import {
  createCurrentEntityModule,
  createEntityListModule,
  CurrentEntityState,
  EntityListState,
} from './utils';

import documentRows, {DocumentRowsState,} from './estate-inventory/document-rows';
import statementRows, {StatementRowsState,} from './estate-inventory/statement-rows';
import {createApi} from "@/api";
import {EstateDistribution} from "@/models/estatedistribution/estateDistribution";

export interface EstateDistributionState {
  list: EntityListState<EstateDistribution>;
  current: CurrentEntityState<EstateDistribution>;
  documentRows: DocumentRowsState;
  statementRows: StatementRowsState;
}

const ENDPOINT = '/api/estates/{caseId}/estate-distributions';

const api = createApi<EstateDistribution, CaseItemParams>(ENDPOINT);

const module: Module<EstateDistributionState, any> = {
  namespaced: true,
  modules: {
    list: createEntityListModule<EstateDistribution, CaseItemParams>(api, {
      apiParamsGetter: CASE_API_PARAMS_GETTER,
    }),
    current: createCurrentEntityModule<EstateDistribution>({
      listStatePath: 'estateDistribution.list',
    }),
    // nested modules
    documentRows,
    statementRows,
  },
  mutations: {
    reset() {}, // eslint-disable-line @typescript-eslint/no-empty-function
  },
  getters: {
    apiParams(
      state: EstateDistributionState,
      getters: any,
      rootState: any,
      rootGetters: any
    ) {
      return {
        ...rootGetters['case/apiParams'],
        estateDistributionId: state.current.id,
      };
    },
    documentUrl(state: EstateDistributionState, getters: any) {
      return getEstateDistributionDocumentUrl(getters['apiParams']);
    },
  },
};

export default module;
