import { Module } from 'vuex';
import { LoginCredentials, UserSession } from '@/models';
import * as api from '@/api/auth';

import { createLoadingModule, LoadingState } from './utils';

const loadModule = createLoadingModule();

export interface AuthState extends LoadingState {
  data: UserSession | null;
  isAuthenticated: boolean;
  redirectTo: string | null;
}

const initialState: AuthState = {
  data: null,
  redirectTo: null,
  isAuthenticated: false,
  ...(loadModule.state as LoadingState),
};

const module: Module<AuthState, any> = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...loadModule.mutations,
    SET_DATA(state: AuthState, data: UserSession) {
      state.data = data;
    },
    SET_REDIRECT_TO(state: AuthState, path: string | null) {
      state.redirectTo = path;
    },
    SET_AUTHENTICATED(state: AuthState, authenticated: boolean) {
      state.isAuthenticated = authenticated;
    },
    reset() {}, // eslint-disable-line @typescript-eslint/no-empty-function
  },
  actions: {
    async getStatus({ commit }) {
      commit('SET_LOADING', true);
      try {
        const data = await api.getAuth();
        commit('SET_DATA', data);
        commit('SET_AUTHENTICATED', true);
        return true;
      } catch (error) {
        commit('SET_AUTHENTICATED', false);
        console.error(error);
        const status = error.response ? error.response.status : 500;
        if (status === 401) {
          // Unauthorised
          commit('SET_LOAD_ERROR', null);
          commit('SET_DATA', null);
        } else {
          commit('SET_LOAD_ERROR', 'loading.networkError');
        }
      } finally {
        commit('SET_LOADING', false);
      }
      return false;
    },
    async login({ commit }, credentials: LoginCredentials) {
      commit('SET_LOADING', true);
      commit('SET_LOAD_ERROR', null);
      try {
        const data = await api.login(credentials);
        commit('SET_DATA', data);
        commit('SET_AUTHENTICATED', true);
        return true;
      } catch (error) {
        commit('SET_AUTHENTICATED', false);
        console.error(error);
        const status = error.response ? error.response.status : 500;
        commit('SET_DATA', null);
        if (status === 401) {
          // Unauthorised
          commit('SET_LOAD_ERROR', 'auth.invalidCredentials');
        } else {
          commit('SET_LOAD_ERROR', 'loading.networkError');
        }
      } finally {
        commit('SET_LOADING', false);
      }
      return false;
    },
    async logout({ commit }) {
      commit('SET_LOADING', true);
      try {
        await api.logout();
        commit('reset');
        return true;
      } catch (error) {
        console.error(error);
        const status = error.response ? error.response.status : 500;
        if (status === 401) {
          commit('SET_LOAD_ERROR', null);
        } else {
          commit('SET_LOAD_ERROR', 'loading.networkError');
        }
      } finally {
        commit('SET_DATA', null);
        commit('SET_LOADING', false);
      }
      return false;
    },
  },
};

export default module;
