import VueI18n from 'vue-i18n';
import * as yup from 'yup';

yup.addMethod(yup.string, 'emptyToNull', function() {
  return this.transform(function(value) {
    if (value === '') {
      return null;
    }
    return value;
  });
});

yup.addMethod(yup.number, 'emptyToNull', function() {
  return this.transform(function(value, rawValue) {
    if (rawValue === '') {
      return null;
    }
    return value;
  });
});

declare module 'yup' {
  interface StringSchema<T> {
    emptyToNull(): StringSchema<T>;
  }

  interface NumberSchema<T> {
    emptyToNull(): NumberSchema<T>;
  }
}

export default function(i18n: VueI18n) {
  function translate(key: string, values?: VueI18n.Values) {
    return i18n.t(`form.validation.${key}`, values).toString();
  }

  yup.setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: translate('mixed.default'),
      defined: translate('mixed.required'),
      required: translate('mixed.required'),
      oneOf: ({ values }) => translate('mixed.oneOf', { values }),
      notOneOf: ({ values }) => translate('mixed.notOneOf', { values }),
      notType: translate('mixed.default'),
    } as yup.MixedLocale,
    string: {
      length: ({ length }) => translate('string.length', { length }),
      min: ({ min }) => translate('string.min', { min }),
      max: ({ max }) => translate('string.max', { max }),
      matches: ({ regex }) => translate('string.matches', { regex }),
      email: ({ regex }) => translate('string.email', { regex }),
      url: ({ regex }) => translate('string.url', { regex }),
      trim: translate('string.trim'),
      lowercase: translate('string.lowercase'),
      uppercase: translate('string.uppercase'),
    },
    // use functions to generate an error object that includes the value from the schema
    number: {
      min: ({ min }) => translate('number.min', { min }),
      max: ({ max }) => translate('number.max', { max }),
      lessThan: ({ less }) => translate('number.lessThan', { less }),
      moreThan: ({ more }) => translate('number.moreThan', { more }),
      positive: ({ more }) => translate('number.positive', { more }),
      negative: ({ less }) => translate('number.negative', { less }),
      integer: translate('number.integer'),
    },
    date: {
      min: ({ min }) => translate('date.min', { min }),
      max: ({ max }) => translate('date.max', { max }),
    },
  });
}
