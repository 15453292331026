import {Module} from 'vuex';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';

import {
  EstateDivisionParams,
  Party,
  PartyRelation,
} from '@/models';
import {ESTATE_DIVISION_API_PARAMS_GETTER} from '@/constants';
import api from '@/api/estate-division/party';

import {
  createCurrentEntityModule,
  createEntityListModule,
  createSavingModule,
  CurrentEntityState,
  EntityListState,
  SavingState,
} from '../utils';

const saveModule = createSavingModule();

export type PartyState = SavingState & {
    list: EntityListState<Party>;
    current: CurrentEntityState<Party>;
};

const module: Module<PartyState, any> = {
    namespaced: true,
    state: {
        ...(saveModule.state as SavingState),
    } as PartyState,
    modules: {
        list: createEntityListModule<Party, EstateDivisionParams>(api, {
            apiParamsGetter: ESTATE_DIVISION_API_PARAMS_GETTER,
        }),
        current: createCurrentEntityModule<Party>({
            listStatePath: 'estateDivision.party.list',
        }),
    },
    mutations: {
        ...saveModule.mutations,
    },
    getters: {
        apiParams(state: any, rootState: any, rootGetters: any) {
            return {
                ...rootGetters[ESTATE_DIVISION_API_PARAMS_GETTER],
                partyId: state.current.id,
            };
        },
        relations(state: any) {
            let relations = state.list.entities.map((e: Party) => e.relations);
            relations = flatten(relations);
            return uniqBy(relations, (r: PartyRelation) => r.id);
        },
        hasSpouse(state: any, getters: any) {
            return getters['relations'].some(
                (r: PartyRelation) =>
                    r.targetEntityType === 'CASE_ITEM_PERSON' && r.type === 'SPOUSE'
            );
        },
        hasPartner(state: any, getters: any) {
            return getters['relations'].some(
                (r: PartyRelation) =>
                    r.targetEntityType === 'CASE_ITEM_PERSON' && r.type === 'PARTNER'
            );
        },
    },
    actions: {
    },
};

export default module;
