import { CaseDocumentType, CaseItem, CaseItemParams, Params } from '@/models';
import { client, createApi } from './';

const ENDPOINT = '/api/cases';

const api = createApi<CaseItem>(ENDPOINT);

export interface CaseItemListParams {
  // only retrieve these specific ids
  id?: number | number[] | null;
  // only retrieve case items for office ids
  office?: number | number[] | null;
  from?: string | null; // should be ISO date
  to?: string | null; // should be ISO date
}

export default {
  ...api,
  // Explicitly type list params
  list: api.list as (
    pathParams?: Params,
    params?: CaseItemListParams
  ) => Promise<CaseItem[]>,
  createDocument<T extends CaseDocumentType>(
    params: CaseItemParams,
    data: { type: T }
  ): Promise<T> {
    const { caseId } = params;
    return client
      .post<T>(`/api/estates/${caseId}/documents`, data)
      .then(response => response.data);
  },
};
