import {
  CaseItemParams,
  Ownership,
  Transaction,
  TransferRequest,
} from '@/models';

import { client, createApi, createEndpoint } from './';

const ENDPOINT = '/api/estates/{caseId}/transactions';

export default {
  ...createApi<Transaction, CaseItemParams>(ENDPOINT),
  transfer(params: CaseItemParams, data: TransferRequest) {
    return client
      .post<Transaction[]>(`${createEndpoint(ENDPOINT, params)}/transfer`, data)
      .then(response => response.data);
  },
  bankAccounts(params: CaseItemParams) {
    return client
      .get<Ownership[]>(`${createEndpoint(ENDPOINT, params)}/bank-accounts`)
      .then(response => response.data);
  },
};
