




























































































































import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import * as yup from 'yup';

//import { CASE_TYPES, CASE_TYPE_TRANSLATIONS } from '@/constants';
//import { selectItems } from '@/utils/form';
import { createEntitySnackbar } from '@/utils/snackbar';
import { isPidNumber, validatePid } from '@/utils/validation';
import { unflattenPerson } from '@/utils';

import ErrorAlert from '@/components/alerts/ErrorAlert.vue';
import FormActions from '@/components/form/FormActions.vue';
import Form from '@/components/form/Form.vue';
import FormFields from '@/components/form/FormFields.vue';
import SparSearch from '@/components/utils/SparSearch.vue';
import {OWNERSHIP_TYPES} from "@/constants";

export default Vue.extend({
  name: 'CreateCaseForm',
  components: {
    ErrorAlert,
    Form,
    FormFields,
    FormActions,
    SparSearch,
  },
  data() {
    return {
      disabled: false,
      disableDirtyCheck: false,
      fields: [
        /*
        {
          name: 'caseType',
          default: CASE_TYPES[0],
          schema: yup.string().required(),
          //.oneOf<CaseType>(CASE_TYPES),
        },
        */
        {
          name: 'personalIdNumber',
          schema: yup
            .string()
            .nullable()
            .test({
              name: 'pid',
              test: function(value: string) {
                if (isPidNumber(value)) {
                  return validatePid(this, value);
                }
                return true;
              },
            })
            .trim()
            .emptyToNull(),
        },
        {
          name: 'firstName',
          schema: yup.string().required(),
        },
        {
          name: 'lastName',
          schema: yup.string().required(),
        },
      ],
    };
  },
  computed: {
    ...mapState('case/list', ['saveError', 'isSaving']),
  },
  methods: {
    ...mapMutations('case/list', { setSaveError: 'SET_SAVE_ERROR' }),
    async save(values: any) {
      const person = unflattenPerson(values);
      //values.caseType = 'ESTATE';
      const entity = await this.$store.dispatch('case/list/create', {
        person,
        caseType: 'ESTATE',
        standardOwnershipType: 'MARITAL'
      });

      this.$snackbar(
        createEntitySnackbar('create', 'caseItem', { success: !!entity })
      );

      if (entity) {
        this.disableDirtyCheck = true;
        Vue.nextTick(() => this.$emit('created', entity));
      }
    },
    onCancel() {
      this.setSaveError(null);
      this.$emit('close');
    },
    pidHint(pid: string | null): string | void {
      if (!!pid && !isPidNumber(pid)) {
        return this.$t('form.deceased.pid.incorrect').toString();
      }
    },
  },
});
