import { EstateInventoryParams, Party, PartySummonData } from '@/models';

import { client, createApi, createEndpoint } from '../';

const ENDPOINT =
  '/api/estates/{caseId}/estate-inventories/{estateInventoryId}/parties';

export default {
  ...createApi<Party, EstateInventoryParams>(ENDPOINT),
  summons(params: EstateInventoryParams, data: PartySummonData[]) {
    return client
      .put<Party[]>(`${createEndpoint(ENDPOINT, params)}/summons`, data)
      .then(response => response.data);
  },
};
