









import Vue from 'vue';
import { Health } from '@/models';

export default Vue.extend({
  name: 'DeploymentInfo',
  props: {
    health: {
      type: Object as () => Health,
      default: null,
    },
  },
  methods: {
    copy() {
      const text = `${process.env.VUE_APP_API_BASE_URL}\n${this.displayText}`;
      navigator.clipboard
        .writeText(text)
        // eslint-disable-next-line no-console
        .then(() => console.log('copied to clipboard', text));
    },
  },
  computed: {
    frontendText(): string {
      const {
        VUE_APP_ENV_NAME,
        VUE_APP_GIT_BRANCH,
        VUE_APP_GIT_COMMIT,
        VUE_APP_BUILD_NUMBER,
      } = process.env;

      return `${VUE_APP_ENV_NAME} (${VUE_APP_GIT_BRANCH}#${VUE_APP_GIT_COMMIT}:${VUE_APP_BUILD_NUMBER})`;
    },
    backendText(): string {
      const { environment, branch, commit, buildNumber } = this.health || {};
      return `${environment} (${branch}#${commit}:${buildNumber})`;
    },
    displayText(): string {
      let text = `fe:${this.frontendText}`;
      if (this.hasBackendInfo) {
        text += `\nbe:${this.backendText}`;
      }
      return text;
    },
    hasBackendInfo(): boolean {
      return !!this.health;
    },
  },
});
