import { Module } from 'vuex';
import { CaseDocumentType, CaseItem, CaseItemParams } from '@/models';
import caseApi from '@/api/case';

import {
  createCurrentEntityModule,
  createEntityListModule,
  createSavingModule,
  CurrentEntityState,
  EntityListState,
  SavingState,
} from './utils';

export type CaseState = SavingState & {
  list: EntityListState<CaseItem>;
  current: CurrentEntityState<CaseItem>;
};

const saveModule = createSavingModule();
const module: Module<CaseState, any> = {
  namespaced: true,
  state: {
    ...(saveModule.state as SavingState),
  } as CaseState,
  modules: {
    list: createEntityListModule<CaseItem>(caseApi),
    current: createCurrentEntityModule<CaseItem>({
      listStatePath: 'case.list',
    }),
  },
  getters: {
    apiParams(state: any) {
      return { caseId: state.current.id };
    },
  },
  mutations: {
    ...saveModule.mutations,
    reset() {}, // eslint-disable-line @typescript-eslint/no-empty-function
  },
  actions: {
    async createDocument<T extends CaseDocumentType>(
      { commit }: any,
      payload: { params: CaseItemParams; data: { type: T } }
    ): Promise<T | boolean> {
      const { params, data } = payload;
      commit('SET_SAVING', true);
      try {
        const result = await caseApi.createDocument(params, data);
        commit('SET_SAVE_ERROR', null);
        return result;
      } catch (error) {
        console.error(error);
        commit('SET_SAVE_ERROR', error);
      } finally {
        commit('SET_SAVING', false);
      }
      return false;
    },
  },
};

export default module;
