import {deleteRequest, ENDPOINTS, get, patch, put} from "@/api/mainApi";
import {showFailedSnackbar} from "@/utils/snackbar";
import {EstateInventory} from "@/models/entities";
import {RequestResponse} from "@/models/api/requestResponse";

async function getLatestEstateInventory(
    caseId: number,
): Promise<EstateInventory | null> {
    try {
        return await get(
            ENDPOINTS.ESTATES +
            '/' +
            caseId +
            '/' +
            ENDPOINTS.ESTATE_INVENTORIES +
            '/' +
            ENDPOINTS.LATEST
        )
    } catch (e) {
        showFailedSnackbar('estateInventory', 'get');
    }
    return null;
}

async function getById(
    caseId: number,
    estateInventoryId: number,
): Promise<EstateInventory | null> {
    try {
        return await get(
            ENDPOINTS.ESTATES +
            '/' +
            caseId +
            '/' +
            ENDPOINTS.ESTATE_INVENTORIES +
            '/' +
            estateInventoryId
        )
    } catch (e) {
        showFailedSnackbar('estateInventory', 'get');
    }
    return null;
}

async function updateById(
    caseId: number,
    estateInventoryId: number,
    estateInventory: EstateInventory
): Promise<EstateInventory | null> {
    try {
        return await put(
            ENDPOINTS.ESTATES +
            '/' +
            caseId +
            '/' +
            ENDPOINTS.ESTATE_INVENTORIES +
            '/' +
            estateInventoryId, estateInventory
        )
    } catch (e) {
        showFailedSnackbar('estateInventory', 'put');
    }
    return null;
}

async function patchDivisionRequested(
    caseId: number,
    estateInventoryId: number,
    divisionRequested: boolean
): Promise<RequestResponse | null> {
    try {
        return await patch(
            ENDPOINTS.ESTATES +
            '/' +
            caseId +
            '/' +
            ENDPOINTS.ESTATE_INVENTORIES +
            '/' +
            estateInventoryId +
            '/' +
            ENDPOINTS.NO_DIVISON_REQUESTED, null, {value: divisionRequested}
        )
    } catch (e) {
        showFailedSnackbar('estateInventory', 'put');
    }
    return null;
}

async function deleteById(
  caseId: number,
  estateInventoryId: number
): Promise<string> {
  try {
    return await deleteRequest(
      ENDPOINTS.ESTATES +
      '/' +
      caseId +
      '/' +
      ENDPOINTS.ESTATE_INVENTORIES +
      '/' +
      estateInventoryId,
    )
  } catch (e) {
    showFailedSnackbar('estateInventory', 'delete');
  }
  return '';
}

export default {
  getLatestEstateInventory,
  getById,
  updateById,
  patchDivisionRequested,
  deleteById
}
