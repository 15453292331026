import { BaseModel, Params } from '@/models';
import { ActionTree, Module } from 'vuex';

import { createLoadingModule, LoadingState } from './loading-module';
import { createSavingModule, SavingState } from './saving-module';

// isSaving means adding to the list, i.e. creating
export type EntityListState<T extends BaseModel> = LoadingState &
  SavingState & {
    entities: T[];
    savingEntities: T['id'][];
    loadingEntities: T['id'][];
  };

interface ListModuleApi<T extends BaseModel, P extends Params = any> {
  list?: (params?: P, queryParams?: Record<string, any>) => Promise<T[]>;
  create?: (params: P, entity: Partial<T>) => Promise<T>;
  get?: (params: P, id: T['id']) => Promise<T>;
  update?: (params: P, entity: Partial<T>) => Promise<T>;
  remove?: (params: P, id: T['id']) => Promise<T['id']>;
}

export function createEntityListModule<
  T extends BaseModel,
  P extends Params = any
>(
  api: ListModuleApi<T, P>,
  options: {
    apiParamsGetter?: string;
  } = {}
): Module<EntityListState<T>, any> {
  const loadModule = createLoadingModule();
  const saveModule = createSavingModule();

  return {
    namespaced: true,
    state: {
      ...(loadModule.state as LoadingState),
      ...(saveModule.state as SavingState),
      entities: [] as T[],
      loadingEntities: [] as T['id'][],
      savingEntities: [] as T['id'][],
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    actions: createEntityListApiActions(api, options),
    mutations: {
      ...loadModule.mutations,
      ...saveModule.mutations,
      // entities
      SET_ENTITIES(state: EntityListState<T>, entities: T[] | null) {
        state.entities = entities || [];
      },
      ADD_ENTITY(state: EntityListState<T>, entity: T) {
        // Add or update entity
        const i = state.entities.findIndex(e => e.id === entity.id);
        if (0 <= i) {
          state.entities.splice(i, 1, entity);
        } else {
          state.entities.push(entity);
        }
      },
      REMOVE_ENTITY(state: EntityListState<T>, id: T['id']) {
        state.entities = [...state.entities.filter(e => e.id != id)];
      },

      // loading
      SET_LOADING_ENTITY(state: EntityListState<T>, id: T['id']) {
        state.loadingEntities.push(id);
      },
      UNSET_LOADING_ENTITY(state: EntityListState<T>, id: T['id']) {
        state.loadingEntities = state.loadingEntities.filter(i => i != id);
      },

      // saving
      SET_SAVING_ENTITY(state: EntityListState<T>, id: T['id']) {
        state.savingEntities.push(id);
      },
      UNSET_SAVING_ENTITY(state: EntityListState<T>, id: T['id']) {
        state.savingEntities = state.savingEntities.filter(i => i != id);
      },
    },
    getters: {
      isLoadingOrSaving: (state: EntityListState<T>) =>
        state.isLoading || state.isSaving,
      loadingOrSavingEntities: (state: EntityListState<T>) => [
        ...state.loadingEntities,
        ...state.savingEntities,
      ],
      isAnyLoadingOrSaving: (state: EntityListState<T>, getters: any) =>
        getters.isLoadingOrSaving ||
        !!state.loadingEntities.length ||
        !!state.savingEntities.length,
    },
  };
}

export function createEntityListApiActions<
  T extends BaseModel,
  P extends Params = any
>(
  api: ListModuleApi<T, P>,
  options: {
    apiParamsGetter?: string;
  }
): ActionTree<EntityListState<T>, any> {
  const actions: ActionTree<EntityListState<T>, any> = {};

  const { list, create, get, update, remove } = api;

  const getParams = (rootGetters: any): P =>
    options.apiParamsGetter ? rootGetters[options.apiParamsGetter] : {};

  if (list) {
    // params can be extra params to add to list function to get special items
    actions.load = async (
      { commit, rootGetters },
      queryParams: Record<string, any>
    ) => {
      // TODO: Take root stae here, to figure out what params should be?
      commit('SET_LOADING', true);
      try {
        const data = await list(getParams(rootGetters), queryParams);
        commit('SET_ENTITIES', data);
        commit('SET_LOAD_ERROR', null);
        return data;
      } catch (error) {
        console.error(error);
        commit('SET_LOAD_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
      return false;
    };
  }

  if (get) {
    actions.get = async ({ commit, rootGetters }, id: number) => {
      commit('SET_LOADING_ENTITY', id);
      try {
        const data = await get(getParams(rootGetters), id);
        commit('ADD_ENTITY', data);
        commit('SET_LOAD_ERROR', null);
        return data;
      } catch (error) {
        console.error(error);
        commit('SET_LOAD_ERROR', error);
      } finally {
        commit('UNSET_LOADING_ENTITY', id);
      }
      return false;
    };
  }

  if (create) {
    actions.create = async ({ commit, rootGetters }, entity: Partial<T>) => {
      commit('SET_SAVING', true);
      try {
        const data = await create(getParams(rootGetters), entity);
        commit('ADD_ENTITY', data);
        commit('SET_SAVE_ERROR', null);
        return data;
      } catch (error) {
        console.error(error);
        commit('SET_SAVE_ERROR', error);
      } finally {
        commit('SET_SAVING', false);
      }
      return false;
    };
  }

  if (update) {
    actions.update = async ({ commit, rootGetters }, entity: Partial<T>) => {
      const { id } = entity;
      commit('SET_SAVING_ENTITY', id);
      try {
        const data = await update(getParams(rootGetters), entity);
        commit('ADD_ENTITY', data);
        commit('SET_SAVE_ERROR', null);
        return data;
      } catch (error) {
        console.error(error);
        commit('SET_SAVE_ERROR', error);
      } finally {
        commit('UNSET_SAVING_ENTITY', id);
      }
      return false;
    };
  }

  if (remove) {
    actions.delete = async ({ commit, rootGetters }, id: T['id']) => {
      commit('SET_SAVING_ENTITY', id);
      try {
        await remove(getParams(rootGetters), id);
        commit('REMOVE_ENTITY', id);
        commit('SET_SAVE_ERROR', null);
        return id;
      } catch (error) {
        console.error(error);
        commit('SET_SAVE_ERROR', error);
      } finally {
        commit('UNSET_SAVING_ENTITY', id);
      }
      return false;
    };
  }

  return actions;
}
