import { Store } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';

export * from './config-module';
export * from './current-entity-module';
export * from './entity-list-module';
export * from './loading-module';
export * from './saving-module';
export * from './single-entity-module';

// adapted from https://github.com/ianwalter/vuex-reset
export function VuexReset(options: any = {}) {
  const { trigger = 'reset' } = options;

  return (store: Store<any>) => {
    // Extract the initial state from the store so that it can be used to reset
    // the store when a trigger mutation is executed.
    const initialState = cloneDeep(store.state);

    store.subscribe((mutation, state) => {
      if (mutation.type === trigger) {
        // Reset the entire store state.
        store.replaceState(cloneDeep(initialState));
      } else {
        // Extract the name of the module and mutation.
        const path = mutation.type.split('/');
        const mut = path.pop();

        if (mut === trigger) {
          // Reset the state for the module containing the mutation.
          const newState = cloneDeep(state);
          const initialModuleState = get(initialState, path);
          set(newState, path, cloneDeep(initialModuleState));

          store.replaceState(newState);
        }
      }
    });
  };
}
