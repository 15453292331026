











































import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

import { SHOW_DIALOG_EVENT, DialogArguments } from '@/plugins/dialog';

export default Vue.extend({
  name: 'Dialog',
  data() {
    return {
      visible: false,
      showDialogHandler: undefined as undefined | Function,
      options: undefined as DialogArguments | undefined,
    };
  },
  created() {
    this.showDialogHandler = this.showDialog.bind(this);
    this.$root.$on(SHOW_DIALOG_EVENT, this.showDialogHandler);
  },
  destroyed() {
    this.$root.$off(SHOW_DIALOG_EVENT, this.showDialogHandler);
  },
  methods: {
    showDialog(options: DialogArguments) {
      if (this.visible) {
        throw new Error(
          'Tried to show a dialog but a dialog is already showing'
        );
      }
      this.options = options;
      this.visible = true;
    },
    close(result?: boolean) {
      this.visible = false;
      this.options?.callback(result);
      // Timeout here to let the transition finish
      setTimeout(() => {
        this.options = undefined;
      }, 300);
    },
  },
  computed: {
    text(): TranslateResult | undefined {
      if (this.options?.text) {
        return this.$t(this.options!.text);
      }
      return undefined;
    },
    additionalText(): TranslateResult | undefined {
      if (this.options?.additionalText) {
        return this.$t(this.options!.additionalText);
      }
      return undefined;
    },
    title(): TranslateResult | undefined {
      if (this.options?.title) {
        return this.$t(this.options!.title);
      }
      return undefined;
    },
    confirm(): boolean {
      return !!this.options?.confirm;
    },
    persistent(): boolean {
      return !!this.options?.persistent;
    },
  },
});
