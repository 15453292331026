import { Module } from 'vuex';
import { Health } from '@/models';
import { getHealth } from '@/api';

import { createLoadingModule, LoadingState } from './utils';

export type HealthState = LoadingState & {
  data: Health | null;
};

const loadModule = createLoadingModule();

const module: Module<HealthState, any> = {
  namespaced: true,
  state: {
    ...(loadModule.state as LoadingState),
    data: null,
  },

  mutations: {
    ...loadModule.mutations,
    SET_DATA(state: HealthState, data: Health) {
      state.data = data;
    },
  },
  actions: {
    async getHealth({ commit }) {
      commit('SET_LOADING', true);
      try {
        const data = await getHealth();
        commit('SET_DATA', data);
        commit('SET_LOAD_ERROR', null);
      } catch (error) {
        console.error(error);
        commit('SET_LOAD_ERROR', error);
        return false;
      } finally {
        commit('SET_LOADING', false);
      }
      return true;
    },
  },
};

export default module;
