











































































import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import {getRoute} from '@/plugins/router';

import {CaseDocumentType, CaseItem, EstateInventory, ModificationTrackedModel} from '@/models';
import {getCaseText} from '@/utils';
import {CASE_DOCUMENT_TYPE_TRANSLATIONS} from '@/constants';
import CreateCaseDocumentForm from '@/components/case/CreateCaseDocumentForm.vue';
import CaseDocumentNavigationSegment
  from "@/components/navigation/CaseDocumentNavigationSegment.vue";
import store from '@/plugins/store';
import {EstateDivision} from "@/models/estate-division/estateDivision";

export default Vue.extend({
  name: 'CaseDocumentNavigation',
  components: {
    CaseDocumentNavigationSegment,
    CreateCaseDocumentForm,
  },
  props: {
    caseItem: {
      type: Object as () => CaseItem,
      default: null,
    },
  },
  data() {
    return {
      CASE_DOCUMENT_TYPE_TRANSLATIONS,
      // create
      showCreateForm: false,
      createFormVisible: false,
      showNavigationOptions: false,
      activeDocumentName: '',
      activeIcon: '',
      icons: {
        estateInventory: 'mdi-format-list-bulleted',
        estateDivision: 'mdi-call-split',
        estateDistribution: 'mdi-scale-balance',
        undecided: 'mdi-file-document-outline',
        additionalEstateInventory: 'mdi-layers-plus'
      }
    };
  },
  computed: {
    ...mapState('case/current', {caseId: 'id'}),
    ...mapState('estateInventory/current', {estateInventoryId: 'id'}),
    ...mapState('estateDivision/current', {estateDivisionId: 'id'}),
    ...mapState('estateDistribution/current', {estateDistributionId: 'id'}),
    ...mapState('auth', {userAccount: 'data'}),
    ...mapGetters('estateInventory/current', {
      entity: 'entity',
      isLoading: 'isLoading',
      isSaving: 'isSaving',
    }),
    estateInventories(): Array<EstateInventory> {
      return this.caseItem.estateInventories.filter((ei: EstateInventory) => !ei.createdFromEstateInventoryId);
    },
    additionalEstateInventories(): Array<EstateInventory> {
      return this.caseItem.estateInventories.filter((ei: EstateInventory) => ei.createdFromEstateInventoryId);
    },
    caseRoute(): string | undefined {
      const route = getRoute('case');
      return route?.path;
    },
    caseItemHasEstateInventories(): boolean {
      return !!this.caseItem?.estateInventories?.length;
    },
    caseItemHasEstateDivisions(): boolean {
      return !!this.caseItem?.estateDivisions?.length;
    },
    caseItemHasEstateDistributions(): boolean {
      return !!this.caseItem?.estateDistributions?.length;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    getCaseText,
    getCaseRoute(id: number): string | undefined {
      return this.caseRoute?.replace(':caseId', id.toString());
    },
    createDocument() {
      this.showCreateForm = true;
    },
    selected(identifier: string, document?: EstateInventory) {
      this.activeDocumentName = this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS[identifier]).toString();
      if (identifier === 'ESTATE_INVENTORY') {
        this.activeIcon = this.icons.estateInventory;
      } else if (identifier === 'ESTATE_DIVISION') {
        this.activeIcon = this.icons.estateDivision;
      } else if (identifier === 'ESTATE_DISTRIBUTION') {
        this.activeIcon = this.icons.estateDistribution;
      } else if (identifier === 'ADDITIONAL_ESTATE_INVENTORY') {
        this.activeIcon = this.icons.additionalEstateInventory;
      }
      store.commit('document/updateActiveDocumentType', identifier);
    },
    onCreate(entity: any, type?: CaseDocumentType) {
      let thing = {};
      if (type) {
        switch (type) {
          case 'ESTATE_INVENTORY':
            thing = {
              name: 'estateInventory',
              params: {
                estateInventoryId: entity.id.toString(),
                caseId: this.caseId.toString(),
              },
            };
            break;
          case 'ESTATE_DIVISION':
            thing = {
              name: 'estateDivision',
              params: {
                estateDivisionId: entity.id.toString(),
                caseId: this.caseId.toString(),
              },
            };
            break;
          case 'ESTATE_DISTRIBUTION':
            thing = {
              name: 'estateDistribution',
              params: {
                estateDistributionId: entity.id.toString(),
                caseId: this.caseId.toString(),
              },
            };
            break;
        }
        this.activeDocumentName = this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS[type]).toString();
        store.commit('document/updateActiveDocumentType', type);
      } else {
        thing = {
          name: 'estateInventory',
          params: {
            estateInventoryId: entity.id.toString(),
            caseId: this.caseId.toString(),
          },
        };
        this.activeDocumentName = this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_INVENTORY']).toString();
        this.activeIcon = this.icons.estateInventory;
        store.commit('document/updateActiveDocumentType', 'ESTATE_INVENTORY');
      }

      this.$store.dispatch('case/list/get', this.caseItem.id);
      this.showCreateForm = false;
      this.$router.push(thing);
    },
    assignDocumentsInfo() {
      if (this.estateInventoryId) {
        this.activeDocumentName = this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_INVENTORY']).toString();
        this.activeIcon = this.icons.estateInventory;
      } else if (this.estateDivisionId) {
        this.activeDocumentName = this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_DIVISION']).toString();
        this.activeIcon = this.icons.estateDivision;
      } else if (this.estateDistributionId) {
        this.activeDocumentName = this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_DISTRIBUTION']).toString();
        this.activeIcon = this.icons.estateDistribution;
      } else {
        this.activeDocumentName = this.$t('generic.navigation.selectDocument').toString();
        this.activeIcon = this.icons.undecided;
      }
    }
  },
  mounted() {
    this.assignDocumentsInfo();
  },
  watch: {
    entity(val) {
      if (val && this.activeDocumentName === this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS['ESTATE_INVENTORY']).toString()) {
        if (val.createdFromEstateInventoryId) {
          this.activeDocumentName = this.$t(CASE_DOCUMENT_TYPE_TRANSLATIONS['ADDITIONAL_ESTATE_INVENTORY']).toString();
          this.activeIcon = this.icons.additionalEstateInventory;
        }
      }
    },
    showCreateForm(val) {
      if (val) {
        this.createFormVisible = true;
      } else if (this.createFormVisible) {
        setTimeout(() => {
          this.createFormVisible = false;
        }, 300);
      }
    },
    createFormVisible(val) {
      if (!val && this.showCreateForm) {
        setTimeout(() => {
          this.showCreateForm = false;
        }, 300);
      }
    },
  },
});
