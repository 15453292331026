import { Module } from 'vuex';

import { CASE_API_PARAMS_GETTER } from '@/constants';
import {
  CaseItemParams,
  Ownership,
  Transaction,
  TransferRequest,
} from '@/models';
import api from '@/api/transaction';
import { getTransactionsUrl } from '@/api/document';

import {
  createCurrentEntityModule,
  createEntityListModule,
  createLoadingModule,
  createSavingModule,
  LoadingState,
  SavingState,
} from './utils';

const saveModule = createSavingModule();
const loadModule = createLoadingModule();

export type TransactionState = SavingState &
  LoadingState & {
    bankAccounts: Ownership[];
  };

const module: Module<TransactionState, any> = {
  namespaced: true,
  state: {
    ...(saveModule.state as SavingState),
    ...(loadModule.state as LoadingState),
    bankAccounts: [] as Ownership[],
  },
  modules: {
    list: createEntityListModule<Transaction, CaseItemParams>(api, {
      apiParamsGetter: CASE_API_PARAMS_GETTER,
    }),
    current: createCurrentEntityModule<Transaction>({
      listStatePath: 'transaction.list',
    }),
  },
  mutations: {
    ...saveModule.mutations,
    ...loadModule.mutations,
    SET_BANK_ACCOUNTS(state: TransactionState, entities: Ownership[]) {
      state.bankAccounts = entities;
    },
  },
  getters: {
    inClientFund(state: any): Transaction[] {
      return state.list.entities.filter(
        (e: Transaction) => e.type === 'CLIENT_FUNDS'
      );
    },
    byAccount(state: any) {
      const transactions = state.list.entities.filter(
        (e: Transaction) => e.type === 'BANK_ACCOUNT'
      );

      return transactions.reduce((acc: any, current: Transaction) => {
        if (!acc[current.accountId!]) {
          acc[current.accountId!] = [];
        }
        acc[current.accountId!].push(current);

        return acc;
      }, {});
    },
    documentUrl(
      state: TransactionState,
      getters: any,
      rootState: any,
      rootGetters: any
    ) {
      return getTransactionsUrl(rootGetters[CASE_API_PARAMS_GETTER]);
    },
  },
  actions: {
    async createTransfer({ commit, rootGetters }, req: TransferRequest) {
      commit('SET_SAVING', true);
      commit('SET_SAVE_ERROR', null);
      try {
        const data = await api.transfer(
          rootGetters[CASE_API_PARAMS_GETTER],
          req
        );
        data.map((e: Transaction) => commit('list/ADD_ENTITY', e));
        return data;
      } catch (error) {
        console.error(error);
        commit('SET_SAVE_ERROR', error);
      } finally {
        commit('SET_SAVING', false);
      }
      return false;
    },
    async loadBankAccounts({ commit, rootGetters }) {
      commit('SET_LOADING', true);
      commit('SET_LOAD_ERROR', null);
      try {
        const data = await api.bankAccounts(
          rootGetters[CASE_API_PARAMS_GETTER]
        );
        commit('SET_BANK_ACCOUNTS', data);
        return data;
      } catch (error) {
        console.error(error);
        commit('SET_LOAD_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
      return false;
    },
  },
};

export default module;
