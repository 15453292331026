

















































import Vue from 'vue';
import {mapState} from 'vuex';

import CaseNavigation from '@/components/navigation/CaseNavigation.vue';
import Dialog from '@/components/Dialog.vue';
import EstateInventoryNavigation from '@/components/navigation/EstateInventoryNavigation.vue';
import LoginOverlay from '@/components/auth/LoginOverlay.vue';
import Navigation from '@/components/navigation/Navigation.vue';
import Snackbar from '@/components/Snackbar.vue';
import EstateDivisionNavigation from '@/components/navigation/EstateDivisionNavigation.vue';
import {UserRole} from '@/enums/user-role';
import store from "@/plugins/store";
import {DocumentType} from "@/enums/document-type";
import EstateDistributionNavigation from "@/components/navigation/EstateDistributionNavigation.vue";

export default Vue.extend({
  name: 'App',
  components: {
    EstateDistributionNavigation,
    CaseNavigation,
    Dialog,
    EstateInventoryNavigation,
    EstateDivisionNavigation,
    LoginOverlay,
    Navigation,
    Snackbar,
  },
  data() {
    return {
      drawer: false,
      estateInventoryDocumentSelected: false,
      estateDivisionDocumentSelected: false,
      estateDistributionDocumentSelected: false
    };
  },
  computed: {
    ...mapState('case/current', {caseId: 'id'}),
    ...mapState('estateInventory/current', {estateInventoryId: 'id'}),
    ...mapState('estateDivision/current', {estateDivisionId: 'id'}),
    ...mapState('estateDistribution/current', {estateDistributionId: 'id'}),
    ...mapState('auth', {userAccount: 'data'}),
    ...mapState('document', {activeDocumentType: 'activeDocumentType'}),
    isInDocument(): boolean {
      // TODO: Would needto check (or make vuex getter) additional document ids
      // when added
      return !!this.estateInventoryId;
    },
    isSysAdminRole(): boolean {
      return this.userAccount && this.userAccount.role === UserRole.SYSTEM_ADMIN;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.estateInventoryId) {
        store.commit('document/updateActiveDocumentType', 'ESTATE_INVENTORY');
      } else if (this.estateDivisionId) {
        store.commit('document/updateActiveDocumentType', 'ESTATE_DIVISION');
      } else if (this.estateDistributionId) {
        store.commit('document/updateActiveDocumentType', 'ESTATE_DISTRIBUTION');
      }
    }, 300)
  },
  methods: {
    documentSelected(identifier: DocumentType) {
      if (identifier === DocumentType.ESTATE_INVENTORY) {
        this.estateInventoryDocumentSelected = true;

        this.estateDivisionDocumentSelected = false;
        this.estateDistributionDocumentSelected = false;
      } else if (identifier === DocumentType.ESTATE_DIVISION) {
        this.estateDivisionDocumentSelected = true;

        this.estateInventoryDocumentSelected = false;
        this.estateDistributionDocumentSelected = false;
      } else if (identifier === DocumentType.ESTATE_DISTRIBUTION) {
        this.estateDistributionDocumentSelected = true;

        this.estateInventoryDocumentSelected = false;
        this.estateDivisionDocumentSelected = false;
      }
    }
  },
  watch: {
    // This is required to not get overlay stuck when resizing
    '$vuetify.breakpoint.mobile'(val, oldVal) {
      if (val && !oldVal) {
        this.drawer = false;
      } else if (!val && oldVal) {
        this.drawer = true;
      }
    },
    activeDocumentType(value: DocumentType) {
      this.documentSelected(value);
    }
  },
});
