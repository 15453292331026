import { Module } from 'vuex';

import { CASE_API_PARAMS_GETTER } from '@/constants';
import { Deceased } from '@/models';
import api from '@/api/deceased';

import { createSingleEntityModule, SingleEntityState } from './utils';

export interface DeceasedState {
  entity: SingleEntityState<Deceased>;
}

const module: Module<DeceasedState, any> = {
  namespaced: true,
  modules: {
    entity: createSingleEntityModule<Deceased>(
      {
        apiParamsGetter: CASE_API_PARAMS_GETTER,
      },
      api
    ),
  },
};

export default module;
