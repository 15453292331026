var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('generic.filter'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{class:['custom-table', 'dont-break', _vm.disabled && 'disabled'],attrs:{"headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.filter,"height":_vm.tableHeight,"items-per-page":-1,"items":_vm.items,"options":{
      sortBy: ['updatedAt'],
      sortDesc: [true],
    },"hide-default-footer":""},on:{"click:row":_vm.select},scopedSlots:_vm._u([{key:"item.remove",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.deceased",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.deceasedName)+" ")]}},{key:"item.personalIdNumber",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.person.personalIdNumber)+" ")]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$rd(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.updatedAt !== item.createdAt ? _vm.$rd(item.updatedAt) : '')+" ")]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"collapsed"},[_vm._v(" "+_vm._s(item.typeText)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }