import get from 'lodash/get';
import { Module } from 'vuex';

import { BaseModel } from '@/models';

export type CurrentEntityState<T extends BaseModel> = {
  id: T['id'] | null;
};

export function createCurrentEntityModule<T extends BaseModel>(options?: {
  listStatePath?: string;
}): Module<CurrentEntityState<T>, any> {
  const module: Module<CurrentEntityState<T>, any> = {
    namespaced: true,
    state: {
      id: null,
    },
    mutations: {
      SET_ID(state: CurrentEntityState<T>, id: T['id'] | null) {
        state.id = id;
      },
    },
  };

  const { listStatePath } = options || {};
  if (listStatePath) {
    module.getters = {
      entity: (state: any, getters: any, rootState: any) =>
        get(rootState, listStatePath).entities.find(
          (e: T) => e.id === state.id
        ),

      isLoading: (state: any, getters: any, rootState: any) =>
        get(rootState, listStatePath).loadingEntities.includes(state.id),

      isSaving: (state: any, getters: any, rootState: any) =>
        get(rootState, listStatePath).savingEntities.includes(state.id),

      isLoadingOrSaving: (state: any, getters: any) =>
        getters.isLoading || getters.isSaving,
    };

    module.actions = {
      load: async ({ dispatch, state }) =>
        dispatch(`${listStatePath.replaceAll('.', '/')}/get`, state.id, {
          root: true,
        }),
    };
  }

  return module;
}
