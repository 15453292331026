















































































import Vue from 'vue';
import * as yup from 'yup';
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

//import * as sb from '@/utils/snackbar';
import store from '@/plugins/store';
import { LoginCredentials } from '@/models';

import ErrorAlert from '@/components/alerts/ErrorAlert.vue';
import Form from '@/components/form/Form.vue';
import FormFields from '@/components/form/FormFields.vue';

import PasswordInputComponent from '@/components/form/PasswordInputComponent.vue';

export default Vue.extend({
  components: {
    ErrorAlert,
    Form,
    FormFields,
    PasswordInputComponent,
  },
  props: {
    value: {
      type: Object as () => Partial<LoginCredentials>,
      required: false,
    },
  },
  data() {
    return {
      data: cloneDeep(this.value),
      fields: [
        {
          name: 'username',
          schema: yup
            .string()
            .required()
            .trim(),
        },
        {
          name: 'password',
          schema: yup
            .string()
            .required()
            .trim(),
        },
      ],
    };
  },
  beforeCreate: () => {
    // we don't wanna display existing auth erro from getStatus here
    store.commit('auth/SET_LOAD_ERROR', null);
  },
  computed: {
    ...mapState('auth', ['isLoading', 'loadError']),
  },
  methods: {
    ...mapActions({ login: 'auth/login' }),
    onSubmit(data: Partial<LoginCredentials>) {
      this.login(data);
    },
  },
});
