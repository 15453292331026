var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('h1',{staticClass:"text-sm-h5 text-xs-h5 mb-2"},[_vm._v(_vm._s(_vm.$t('form.caseItem.title')))]),_c('Form',{ref:"form",attrs:{"disable-dirty-check":_vm.disableDirtyCheck},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var valid = ref.valid;
      var dirty = ref.dirty;
      var submit = ref.submit;
return [(!!_vm.saveError)?_c('ErrorAlert',{attrs:{"error":_vm.saveError.toString(),"title":_vm.$t('loading.saveError')}}):_vm._e(),_c('FormActions',{attrs:{"disabled":_vm.disabled,"valid":valid,"dirty":dirty,"saving":_vm.isSaving,"hide-add-more":""},on:{"add":submit,"cancel":_vm.onCancel}})]}}])},[_c('FormFields',{attrs:{"fields":_vm.fields},scopedSlots:_vm._u([{key:"fields",fn:function(ref){
        var values = ref.values;
        var fieldInput = ref.fieldInput;
        var validateField = ref.validateField;
        var hasError = ref.hasError;
        var getError = ref.getError;
        var submit = ref.submit;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SparSearch',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var loading = ref.loading;
        var search = ref.search;
return [_c('v-text-field',{attrs:{"autofocus":"","autocomplete":"off","label":_vm.$t('form.deceased.pid.label'),"hint":_vm.pidHint(values.personalIdNumber),"persistent-hint":"","error":hasError('personalIdNumber'),"error-messages":getError('personalIdNumber'),"loading":loading,"append-outer-icon":"mdi-magnify"},on:{"blur":function($event){return validateField('personalIdNumber')},"input":function($event){return fieldInput('personalIdNumber')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return search(
                      values.personalIdNumber,
                      values,
                      _vm.$refs.form.validate
                    )},"click:append-outer":function($event){return search(
                      values.personalIdNumber,
                      values,
                      _vm.$refs.form.validate
                    )}},model:{value:(values.personalIdNumber),callback:function ($$v) {_vm.$set(values, "personalIdNumber", $$v)},expression:"values.personalIdNumber"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":_vm.$t('form.deceased.firstName.label'),"hint":_vm.$to('form.deceased.firstName.hint'),"error":hasError('firstName'),"error-messages":getError('firstName')},on:{"blur":function($event){return validateField('firstName')},"input":function($event){return fieldInput('firstName')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return submit($event)}},model:{value:(values.firstName),callback:function ($$v) {_vm.$set(values, "firstName", $$v)},expression:"values.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":_vm.$t('form.deceased.lastName.label'),"hint":_vm.$to('form.deceased.lastName.hint'),"error":hasError('lastName'),"error-messages":getError('lastName')},on:{"input":function($event){return fieldInput('lastName')},"blur":function($event){return validateField('lastName')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return submit($event)}},model:{value:(values.lastName),callback:function ($$v) {_vm.$set(values, "lastName", $$v)},expression:"values.lastName"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }