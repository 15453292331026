








































import Vue from 'vue';
import { mapState } from 'vuex';

import { CaseItem } from '@/models';
import { getCaseText } from '@/utils';

export default Vue.extend({
  name: 'CaseNavigation',
  components: {},
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState('case/current', { caseId: 'id' }),
    ...mapState('estateInventory/current', { estateInventoryId: 'id' }),

    caseItem(): CaseItem | null {
      return this.$store.getters['case/list/entity'](this.caseId);
    },
    caseText(): string {
      return getCaseText.bind(this)(this.caseItem as CaseItem | null);
    },
    params() {
      const { caseId, estateInventoryId } = this;
      return { caseId, estateInventoryId };
    },
  },
});
