import { Module } from 'vuex';

import { CaseItemParams, EstateInventory } from '@/models';
import { CASE_API_PARAMS_GETTER } from '@/constants';

import api from '@/api/estate-inventory';
import { getEstateInventoryDocumentUrl } from '@/api/document';

import {
  createCurrentEntityModule,
  createEntityListModule,
  CurrentEntityState,
  EntityListState,
} from './utils';

import party, { PartyState } from './estate-inventory/party';
import ownership, { OwnershipState } from './estate-inventory/ownership';
import documentRows, {
  DocumentRowsState,
} from './estate-inventory/document-rows';
import statementRows, {
  StatementRowsState,
} from './estate-inventory/statement-rows';

export interface EstateInventoryState {
  list: EntityListState<EstateInventory>;
  current: CurrentEntityState<EstateInventory>;
  party: PartyState;
  ownership: OwnershipState;
  documentRows: DocumentRowsState;
  statementRows: StatementRowsState;
  createdFromEstateInventoryId: number;
  incomingBalanceDeceased: number | null;
  incomingBalanceRemainder: number | null;
}

const module: Module<EstateInventoryState, any> = {
  namespaced: true,
  modules: {
    list: createEntityListModule<EstateInventory, CaseItemParams>(api, {
      apiParamsGetter: CASE_API_PARAMS_GETTER,
    }),
    current: createCurrentEntityModule<EstateInventory>({
      listStatePath: 'estateInventory.list',
    }),
    // nested modules
    party,
    ownership,
    documentRows,
    statementRows,
  },
  mutations: {
    reset() {}, // eslint-disable-line @typescript-eslint/no-empty-function
  },
  getters: {
    apiParams(
      state: EstateInventoryState,
      getters: any,
      rootState: any,
      rootGetters: any
    ) {
      return {
        ...rootGetters['case/apiParams'],
        estateInventoryId: state.current.id,
      };
    },
    documentUrl(state: EstateInventoryState, getters: any) {
      return getEstateInventoryDocumentUrl(getters['apiParams']);
    },
  },
};

export default module;
