import Vue from 'vue';
import { Route } from 'vue-router';

import router from '@/plugins/router';
import vuetify from '@/plugins/vuetify';
import store from '@/plugins/store';
import i18n from '@/plugins/i18n';
import SnackbarPlugin from '@/plugins/snackbar';
import DialogPlugin from '@/plugins/dialog';
import Fragments from 'vue-fragment';
import setValidationMessages from '@/utils/yup';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'; //Globally import VTextField

import { BEFORE_ROUTE_LEAVE_EVENT } from '@/constants';

import RootApp from '@/apps/RootApp.vue';

import '@/assets/styles.css';

setValidationMessages(i18n);
Vue.use(Fragments.Plugin);
Vue.use(SnackbarPlugin);
Vue.use(DialogPlugin);
Vue.config.productionTip = false;

Vue.component('v-text-field', VTextField);
// default options for currency field. These can be overridden during implementation
// https://phiny1.github.io/v-currency-field/config.html#component-props
Vue.use(VCurrencyField, {
  locale: 'sv-SE',
  decimalLength: 0,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: true,
  allowNegative: false,
});

export const instance = new Vue({
  router,
  vuetify,
  i18n,
  store: store,
  render: h => h(RootApp),
}).$mount('#app');

// Need to keep this here so we can access instance and trigger event
router.beforeEach(async (to: Route, from: Route, next: Function) => {
  const event = { dirty: false };
  instance.$emit(BEFORE_ROUTE_LEAVE_EVENT, event);
  let confirmed = true;

  if (event.dirty) {
    confirmed = await instance.$confirm({
      text: 'form.generic.confirmNavigation',
    });
  }

  if (!confirmed) {
    next(false);
  } else {
    next();
  }
});
