import {
  EstateDivisionParams,
  EstateInventoryParams,
  Party,
  PartySummonData
} from '@/models';

import { client, createApi, createEndpoint } from '../';

const ENDPOINT =
  '/api/estates/{caseId}/estate-divisions/{estateDivisionId}/parties';

export default {
  ...createApi<Party, EstateDivisionParams>(ENDPOINT),
  summons(params: EstateDivisionParams, data: PartySummonData[]) {
    return client
      .put<Party[]>(`${createEndpoint(ENDPOINT, params)}/summons`, data)
      .then(response => response.data);
  },
};
